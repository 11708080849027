import React, { useState } from 'react';
import { FormErrorSummary, Form, FormSubmit, ErrorSummary } from '@deity/falcon-ui-kit';
import { Field, Formik, ErrorMessage } from 'formik';
import { I18n, T } from '@deity/falcon-i18n';
import { useLocation } from 'react-router-dom';
import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';
import { Dropdown } from '../../../../ui/Dropdown/Dropdown';
import { FormField } from '../../../../ui/Forms/FormField';
import { Checkbox } from '../../../../ui/Checkbox/Checkbox';
import { Button } from '../../../../ui/Button/Button';
import { useGlobalStateValue } from '../../../../helpers/GlobalState/GlobalState';
import { useEmailValidatorMutation } from '../../../../helpers/EmailValidation/EmailValidationMutation';
import additionalStyles from '../../../../elements/Cms/Modules/Components/CmsRowHeadline.module.scss';
import { addRecaptchaJs, removeRecaptchaBadge } from '../../../../helpers/Recaptcha/ExternalJs';
import { i18nMarkdown } from '../../../../helpers/Markdown/Markdown';
import loadable from '../../../../../../../components/loadable';
import styles from './ContactForm.module.scss';
import { SetContactFormMutation } from './ContactFormMutation';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../../ui/Modal/Modal'));

export const ContactForm = withStoreConfiguration(({ storeConfiguration, options, variant, ...formProps }) => {
  const query = new URLSearchParams(useLocation().search);
  const [, dispatch] = useGlobalStateValue();
  const [emailValidator] = useEmailValidatorMutation();
  options = JSON.parse(storeConfiguration?.contact?.general?.options);
  const enableModule = 'contact' in storeConfiguration;
  const isContactFormHidden = parseInt(storeConfiguration?.contact?.general?.hide_form, 10);
  const hideForm = enableModule ? isContactFormHidden : false;
  const [subjectReset, setSubjectReset] = useState(0);
  const [dropdownError, setDropdownError] = useState(false);
  const [dropdownPlaceholder, setDropdownPlaceholder] = useState(true);
  const clientVersion = query.get('clientVersion') || '';
  const userAgent = query.get('userAgent') || 'web';
  const subjects = Object.keys(options).map(item => ({
    value: options[item].options,
    label: options[item].options
  }));
  const defaultValue = {
    value: '-1',
    label: <T id="contactForm.dropdownPlaceholder" />
  };
  subjects.unshift(defaultValue);

  const preSelected = subjects[0].value;

  const validate = (values, t) => {
    const errors = {};
    const requiredField = (
      <div className={styles.ContactFormRequired}>
        <T id="contactForm.requiredField" />
      </div>
    );

    if (
      values.email &&
      values.email !== '' &&
      /.+@.+\..{2,}$/.test(values.email) &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = <T id="formError.invalid" label={t('signUp.email')} />;
    } else if (values.email && values.email !== '' && values.email.length > 80) {
      errors.email = <T id="formError.maxLength" maxLength="80" />;
    }

    if (values.firstname !== '' && values.firstname.length > 30) {
      errors.firstname = <T id="formError.maxLength" maxLength="30" />;
    }

    if (values.lastname !== '' && values.lastname.length > 30) {
      errors.lastname = <T id="formError.maxLength" maxLength="30" />;
    }

    if (values.telephone !== '' && values.telephone.length > 30) {
      errors.telephone = <T id="formError.maxLength" maxLength="30" />;
    }

    if (values.comment !== '' && values.comment.length > 2000) {
      errors.comment = <T id="formError.maxLength" maxLength="2000" />;
    }

    if (!values.agbChecked) {
      errors.agbChecked = requiredField;
    }

    if (values.subject.options === '-1') {
      errors.subject = <T id="contactForm.dropdownError" />;
    }

    return errors;
  };

  const showNotification = (type, content) => {
    window.scrollBy(0, -10000);
    dispatch({
      type: 'ADD_TO_STATE',
      section: 'messages',
      data: {
        content,
        type,
        animate: false
      }
    });
  };

  const [showAgbOverlay, setShowAgbOverlay] = useState(false);
  const [showDataProtectionOverlay, setShowDataProtectionOverlay] = useState(false);

  const agbLabel = (
    <I18n>
      {t => (
        <span className="normal">
          {t('contactForm.terms')}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span onClick={() => setShowAgbOverlay(true)}>{t('contactForm.agb')}</span>
          {t('contactForm.termsOne')}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span onClick={() => setShowDataProtectionOverlay(true)}>{t('contactForm.privacyPolicy')}</span>
          {t('contactForm.termsTwo')}
        </span>
      )}
    </I18n>
  );
  // @TODO: agentId and orderNumber for contact form // missing query to get if logged in as agent
  return enableModule ? (
    <I18n>
      {t => (
        <div>
          {!hideForm ? (
            <div className={styles.ContactFormWrapper}>
              <div className={additionalStyles.CmsRowHeadline}>
                <span>{t('contactForm.header')}</span>
                <hr />
              </div>
              <p className={styles.ContactFormTitle}>
                {i18nMarkdown('contactForm.title', { faqLink: t('cms.helpfaq.link') })}
              </p>
              <div className={styles.ContactFormInner}>
                <div className={styles.ContactFormSubheader}>
                  <h4>{t('contactForm.contactUs')}</h4>
                </div>
                <SetContactFormMutation>
                  {setContactForm => (
                    <Formik
                      initialValues={{
                        firstname: '',
                        lastname: '',
                        email: '',
                        telephone: '',
                        subject: { options: subjects[0].value },
                        comment: '',
                        recaptchaToken: '',
                        agbChecked: false,
                        agentId: 'TestAgent',
                        orderNumber: 'TestAgent',
                        userAgent,
                        clientVersion
                      }}
                      /* eslint-disable-next-line no-shadow,camelcase */
                      onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
                        emailValidator({
                          variables: { email: values.email }
                        })
                          .then(() => {
                            addRecaptchaJs(storeConfiguration, () => {
                              const { grecaptcha } = window;
                              grecaptcha.ready(() => {
                                grecaptcha
                                  .execute(storeConfiguration?.asam_recaptcha?.credentials?.site_key, {
                                    action: 'submit'
                                  })
                                  .then(recaptchaToken => {
                                    setContactForm({ variables: { ...values, recaptchaToken } }).then(result => {
                                      removeRecaptchaBadge();
                                      if (result.data.setContactForm.status !== 'success') {
                                        setSubmitting(false);
                                        showNotification('error', t('contactForm.error'));
                                      } else {
                                        setSubmitting(false);
                                        showNotification('success', t('contactForm.success'));
                                        document.getElementById('agbChecked').checked = false;
                                        setSubjectReset(1);
                                        setDropdownPlaceholder(true);
                                        resetForm();
                                      }
                                    });
                                  });
                              });
                            });
                          })
                          .catch(e => {
                            if (e) {
                              setSubmitting(false);
                              setStatus({
                                error: {
                                  message: t('emailValidator.error'),
                                  code: 'INTERNAL_SERVER_ERROR'
                                }
                              });
                            }
                          });
                      }}
                      validate={values => validate(values, t)}
                    >
                      {({ error, status = {} }) => (
                        <Form id="contactForm" {...formProps}>
                          <div className={styles.ContactFormOneLineFields}>
                            <FormField
                              name="firstname"
                              type="text"
                              className={styles.ContactFormField}
                              required
                              showErrorMessage
                              variant="secondary"
                              label={t('contactForm.firstname')}
                              placeholder={t('contactForm.firstname')}
                            />
                            <FormField
                              name="lastname"
                              type="text"
                              className={styles.ContactFormField}
                              required
                              showErrorMessage
                              variant="secondary"
                              label={t('contactForm.lastname')}
                              placeholder={t('contactForm.lastname')}
                            />
                          </div>
                          <FormField
                            name="email"
                            type="email"
                            className={styles.ContactFormField}
                            required
                            showErrorMessage
                            variant="secondary"
                            label={t('contactForm.email')}
                            placeholder={t('contactForm.email')}
                          />
                          <FormField
                            name="telephone"
                            type="text"
                            className={styles.ContactFormField}
                            showErrorMessage
                            label={t('contactForm.telephone')}
                            placeholder={t('contactForm.telephone')}
                            variant="secondary"
                          />
                          <FormField
                            name="subject"
                            required
                            showErrorMessage
                            key={`item-${subjectReset}`}
                            className={[
                              styles.ContactFormField,
                              styles.ContactFormFieldDropdown,
                              dropdownError ? styles.ContactFormFieldDropdownError : null,
                              dropdownPlaceholder ? styles.ContactFormFieldDropdownPlaceholder : null
                            ].join(' ')}
                          >
                            {({ form, field }) => {
                              return (
                                <Dropdown
                                  preSelected={preSelected}
                                  options={subjects}
                                  type="secondary"
                                  withMargin
                                  onChange={value => {
                                    form.setFieldValue(field.name, { options: value });
                                    if (value !== '-1') {
                                      setDropdownError(false);
                                      setDropdownPlaceholder(false);
                                    } else {
                                      setDropdownError(true);
                                      setDropdownPlaceholder(true);
                                    }
                                  }}
                                />
                              );
                            }}
                          </FormField>
                          <FormField
                            className={styles.ContactFormField}
                            required
                            noSign
                            showErrorMessage
                            textarea
                            name="comment"
                            type="text"
                            label={t('contactForm.message')}
                            placeholder={`${t('contactForm.message')} *`}
                            variant="secondary"
                          />
                          <Field
                            className={styles.ContactFormField}
                            required
                            name="agbChecked"
                            render={({ field }) => {
                              return (
                                <div className={styles.ContactFormTerms}>
                                  <Checkbox
                                    name="agbChecked"
                                    id="agbChecked"
                                    type="checkbox"
                                    checked={field.value.agbChecked}
                                    label={agbLabel}
                                    {...field}
                                  />
                                </div>
                              );
                            }}
                          />
                          <ErrorMessage name="agbChecked" />
                          {status.error && <ErrorSummary errors={status.error} />}
                          {storeConfiguration?.contact?.info?.status && storeConfiguration?.contact?.info?.content ? (
                            <div
                              className={
                                storeConfiguration.contact.info.status === 'info'
                                  ? styles.ContactFormInfo
                                  : styles.ContactFormError
                              }
                            >
                              <div className={[styles.ContactFormIcon, 'icon-alert-circle'].join(' ')} />
                              <p className={styles.ContactFormMessage}>{storeConfiguration.contact.info.content}</p>
                            </div>
                          ) : null}
                          <div className={styles.ContactFormButtons}>
                            <FormErrorSummary errors={error && [error.message]} />
                            <FormSubmit>
                              {({ form }) => (
                                <Button type="submit" size="small" loading={form.isSubmitting} variant="secondary">
                                  <T id="contactForm.send" />
                                </Button>
                              )}
                            </FormSubmit>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </SetContactFormMutation>
              </div>
              {showAgbOverlay ? (
                <Modal
                  pageContent={t('cms.agb.link')}
                  variant="tertiary"
                  scrollableContent
                  visible={showAgbOverlay}
                  close={() => {
                    setShowAgbOverlay(false);
                  }}
                />
              ) : null}
              {showDataProtectionOverlay ? (
                <Modal
                  pageContent={t('cms.dataprotection.link')}
                  variant="tertiary"
                  scrollableContent
                  visible={showDataProtectionOverlay}
                  close={() => {
                    setShowDataProtectionOverlay(false);
                  }}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </I18n>
  ) : null;
});
