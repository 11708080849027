import { Mutation } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const SET_QUIZ_ANSWER = gql`
  mutation SetQuizAnswer(
    $participant: QuizParticipant!
    $newsletter_subscriber: Boolean
    $terms: Boolean
    $quizId: String
    $answers: [QuizAnswers!]
  ) {
    setQuizAnswer(
      participant: $participant
      newsletterSubscriber: $newsletter_subscriber
      terms: $terms
      quizId: $quizId
      answers: $answers
    ) {
      success
      message
    }
  }
`;

export class SetQuizAnswerMutation extends Mutation {
  static defaultProps = {
    mutation: SET_QUIZ_ANSWER
  };
}
