import React from 'react';
import Container from '../../../layout/Container/Container';
import { SearchBar } from '../../SearchBar/SearchBar';
import { isAppMobile } from '../../../helpers/AppMobile/AppMobile';
import styles from './CmsModules.module.scss';
import { CmsImage } from './Components/CmsImages';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsHeadline } from './Components/CmsHeadline';
import { CmsRichtext } from './Components/CmsRichText';
import { CmsButton } from './Components/CmsButton';
import { CmsVideo } from './Components/CmsVideo';
import { CmsCfVideo } from './Components/CmsCfVideo';
import { CmsWidget } from './Components/CmsWidget';

const ElementOneColumn = ({ item, row }) => {
  const fontClasses = item.font_color === false ? [styles.FontColorSecondary] : [];
  const optionClasses =
    item.text_align === false
      ? [styles.TextAlign, styles.Column, styles.ColumnFlexOff]
      : [styles.Column, styles.ColumnFlexOff];
  const spaceClasses = item.bottom_space === true ? [styles.SpaceRemoval] : [];
  const wrapperBgColor = item.background_color ? item.background_color : '#ffffff';
  const itemNoPadding = item.no_padding || false;

  const columnWidget = item.widget;
  // remove the contact widget from pages if it is mobile parameter
  if (columnWidget === 'ContactForm' && isAppMobile) {
    return null;
  }
  const columnWidgetSource = item.widget_source;
  const columnCode = item.code;

  if (item.type_area === true) {
    optionClasses.push(styles.TypeArea);
  }

  // Column items needed fields for further optimisation
  const columnImage = item.image && item.image.length ? item.image[0] : null;
  const columnTarget = columnImage ? item.image[0].sys.id : null;
  const columnImageLarge = columnImage ? item.image[0].fields.file.url : null;
  const columnImageSmall = columnImage && item.image[1] ? item.image[1].fields.file.url : null;
  const columnImageAlt = columnImage ? item.image[0].fields : null;
  const columnImageCopyright = item.image_copyright || null;
  const columnVideo = item.video ? item.video : null;
  const columnHeadline = item.headline;
  const columnHeadlineType = item.headline_type;
  const columnText = item.text;
  const columnSmallPrint = item.small_print;
  const columnTextFormat = item.text_align;
  const columnCfVideo = item?.cfVideo?.fields?.file?.url || null;
  const columnCfVideoThumb = item?.cfVideoThumb?.fields?.file?.url || null;
  const columnCfVideoTitle = item?.cfVideo?.fields?.title || null;
  const columnCta = item.cta;
  const columnCtaLink = item.cta_link ? item.cta_link : null;
  const columnCtaLinkReference = item.cta_link_reference ? item.cta_link_reference : null;
  const columnRelatedProduct = item.related_product ? item.related_product : null;

  return (
    <section
      style={{ backgroundColor: `${wrapperBgColor}` }}
      className={spaceClasses.join(' ')}
      data-cms-block="element_1_column"
    >
      <Container className={fontClasses.join(' ')} noPadding={itemNoPadding}>
        <CmsRowHeadline item={item} bgColor={wrapperBgColor} />
        <div
          className={[
            styles.oneColumnRow,
            columnWidget === 'LastSeen' || columnWidget === 'FrontnowSidebar' ? styles.oneColumnNoPadding : null
          ].join(' ')}
        >
          <div className={optionClasses.join(' ')}>
            {columnImage && columnWidget !== 'FrontnowBanner' ? (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className={columnCtaLinkReference || columnRelatedProduct || columnCtaLink ? 'Interactive' : null}
                onClick={
                  columnCtaLinkReference || columnRelatedProduct || columnCtaLink
                    ? () => document.getElementById(`${columnTarget}`).click()
                    : null
                }
              >
                <CmsImage
                  largeImage={columnImageLarge}
                  smallImage={columnImageSmall}
                  imageAlt={columnImageAlt}
                  imgCopyright={columnImageCopyright}
                  source="ElementOneColumn.js"
                  useHeight={false}
                  row={row}
                />
              </div>
            ) : null}
            {!columnImage && columnVideo ? (
              <CmsVideo videoId={columnVideo} videoCopyright={columnImageCopyright} />
            ) : null}
            {columnCfVideo ? (
              <CmsCfVideo videoUrl={columnCfVideo} videoThumb={columnCfVideoThumb} videoTitle={columnCfVideoTitle} />
            ) : null}
            {columnHeadline && columnWidget !== 'FrontnowEmbedded' ? (
              <CmsHeadline headline={columnHeadline} headlineTag={columnHeadlineType} />
            ) : null}
            {columnText && columnWidget !== 'FrontnowEmbedded' ? (
              <CmsRichtext text={columnText} smallPrint={columnSmallPrint} textFormat={columnTextFormat} />
            ) : null}
            {columnCta && (columnCtaLinkReference || columnRelatedProduct || columnCtaLink) ? (
              <CmsButton
                types={!columnHeadline && !columnText && !columnSmallPrint && columnImage ? 'invisible' : null}
                id={columnTarget}
                relativeLink={columnCtaLinkReference}
                absoluteLink={columnCtaLink}
                productLink={columnRelatedProduct}
                buttonText={columnCta}
              />
            ) : null}
            {columnWidget && columnWidget !== 'Searchbar' ? (
              <CmsWidget itemContent={item} widget={columnWidget} widgetSource={columnWidgetSource} />
            ) : null}
            {columnWidget && columnWidget === 'Searchbar' && <SearchBar asWidget />}
            {columnCode ? <CmsRichtext text={columnCode} /> : null}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ElementOneColumn;
