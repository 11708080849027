import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form } from '@deity/falcon-front-kit';
import { I18n } from '@deity/falcon-i18n';
import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';
import { FrontnowTracking } from '../../../Tracking/GoogleTagManager/GA4/FrontnowTracking';
import { Button } from '../../../../ui/Button/Button';
import { FormField } from '../../../../ui/Forms/FormField';
import headerSvg from '../../../../../../../assets/images/sprites/headerSprite.svg';
import styles from './CmsFrontnow.module.scss';
import { CmsRichtext } from './CmsRichText';
import { CmsImage } from './CmsImages';

export const CmsFrontnow = withStoreConfiguration(({ storeConfiguration, widget, itemContent }) => {
  const [show, setShow] = useState(false);
  let frontnowAdvisor;

  const loadFrontnow = () => {
    frontnowAdvisor = new window.Advisor({
      name: 'Beauty Berater',
      subdomain: 'asambeauty',
      mode: 'sidebar',
      target: '#advisorContainer',
      language: 'de',
      customIcon: `${headerSvg}#abWhiteLogo`,
      colorScheme: {
        primary: '#000000'
      }
    });

    frontnowAdvisor?.on('open', () => {
      FrontnowTracking({
        storeConfiguration,
        eventName: 'frontnow_open'
      });
    });

    frontnowAdvisor?.on('prompt', () => {
      FrontnowTracking({
        storeConfiguration,
        eventName: 'frontnow_user_question'
      });
    });

    frontnowAdvisor?.on('suggestionClick', () => {
      FrontnowTracking({
        storeConfiguration,
        eventName: 'frontnow_product_reco_click'
      });
    });

    frontnowAdvisor?.on('click', () => {
      FrontnowTracking({
        storeConfiguration,
        eventName: 'frontnow_carousel_click'
      });
    });
  };

  const consentHandler = event => {
    if (event.detail?.ucCategory?.marketing) {
      setShow(true);
    }
  };

  const openAdvisorSidebar = () => {
    frontnowAdvisor?.open();
  };

  useEffect(() => {
    const existingScript = document.getElementById('frontnow');
    let didAppend = false;

    if (!existingScript && show) {
      const script = document.createElement('script');
      script.src = 'https://cdn.frontnow.site/advisor.js';
      script.async = true;
      script.id = 'frontnow';

      if (!window.Advisor) {
        document.body.appendChild(script);
        didAppend = true;

        script.onload = () => {
          loadFrontnow();
        };
      } else {
        loadFrontnow();
      }

      return () => {
        frontnowAdvisor?.destroy();
        if (didAppend) {
          document.body?.removeChild(script);
        }
      };
    }
  }, [show]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const marketing = window?.UC_UI?.getServicesBaseInfo()?.find(item => item?.categorySlug === 'marketing');
      if (marketing?.consent?.status) {
        setShow(true);
      }
      window.addEventListener('ucEvent', consentHandler);
      return () => window.removeEventListener('ucEvent', consentHandler);
    }
  }, []);

  return show ? (
    <>
      {widget === 'FrontnowEmbedded' ? (
        <I18n>
          {t => (
            <div className={styles.FrontnowEmbeddedWrapper}>
              <img src={`${headerSvg}#logo`} alt="logo" width="240" height="60" />
              <div className={styles.FrontnowEmbeddedContent}>
                {itemContent?.headline ? (
                  <h2 className={styles.FrontnowEmbeddedHeadline}>{itemContent.headline}</h2>
                ) : null}
                <Formik
                  initialValues={{
                    question: ''
                  }}
                  onSubmit={({ question }) => {
                    if (question) {
                      frontnowAdvisor?.prompt(question);
                    }
                  }}
                >
                  {() => (
                    <Form id="frontnow-prompt-form" className={styles.FrontnowEmbeddedForm}>
                      <FormField
                        type="text"
                        name="question"
                        className={styles.EmbeddedFormInput}
                        placeholder={t('frontnow.questionFieldPlaceholder')}
                        hideValidateStyles
                      />
                      {itemContent.cta ? (
                        <Button type="submit" classNames={styles.EmbeddedFormButton}>
                          {itemContent.cta}
                        </Button>
                      ) : null}
                    </Form>
                  )}
                </Formik>
                {itemContent?.text ? (
                  <CmsRichtext text={itemContent.text} textFormat={itemContent?.text_align} />
                ) : null}
              </div>
            </div>
          )}
        </I18n>
      ) : null}
      {widget === 'FrontnowBanner' ? (
        <>
          {itemContent.image ? (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div className="Interactive" onClick={openAdvisorSidebar}>
              <CmsImage
                largeImage={itemContent.image?.[0]?.fields?.file?.url}
                smallImage={itemContent.image?.[1]?.fields?.file?.url}
                imageAlt={itemContent.image?.[0]?.fields}
                imgCopyright={itemContent?.image_copyright}
                source="CmsFrontnow.js"
              />
            </div>
          ) : null}

          {itemContent.cta ? (
            <Button classNames={styles.FrontnowAskButton} onClick={openAdvisorSidebar}>
              {itemContent.cta}
            </Button>
          ) : null}
        </>
      ) : null}
      <div id="advisorContainer" />
    </>
  ) : (
    <></>
  );
});
