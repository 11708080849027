import { Mutation } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const SET_CONTACT_FORM = gql`
  mutation SetContactForm(
    $firstname: String
    $lastname: String
    $email: String
    $telephone: String
    $subject: [ContactFormSubject!]
    $comment: String
    $userAgent: String
    $clientVersion: String
    $recaptchaToken: String
    $agbChecked: Boolean
  ) {
    setContactForm(
      firstname: $firstname
      lastname: $lastname
      email: $email
      telephone: $telephone
      subject: $subject
      comment: $comment
      userAgent: $userAgent
      clientVersion: $clientVersion
      recaptchaToken: $recaptchaToken
      agbChecked: $agbChecked
    ) {
      status
      message
    }
  }
`;

export class SetContactFormMutation extends Mutation {
  static defaultProps = {
    mutation: SET_CONTACT_FORM
  };
}
