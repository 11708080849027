import React from 'react';
import { InnerHTML } from '@deity/falcon-ui-kit';
import Container from '../../../layout/Container/Container';
import { NewsletterInput } from '../../../sections/Newsletter/NewsletterInput';
import styles from './CmsModules.module.scss';
import { CmsImage } from './Components/CmsImages';
import { CmsRichtext } from './Components/CmsRichText';
import { CmsImageBg } from './Components/CmsImagesBg';

const CmsPageNewsletter = ({ item }) => {
  // TODO: move all fields in rezolver of graphQl task
  const contentFields = item;
  // List widget content fields
  const headline = contentFields.headline || '';
  const headlineAlign = contentFields.headline_align === undefined || contentFields.headline_align === true;
  const subHeadline = contentFields.subheadline || '';
  const subHeadlineAlign = contentFields.subheadline_align === undefined || contentFields.subheadline_align === true;
  const textboxBefore = contentFields.textbox_before;
  const textboxBeforeColor =
    contentFields.textbox_fontcolor_before === undefined || contentFields.textbox_fontcolor_before === true;
  const textboxBeforeBgColor = contentFields.textbox_bgcolor_before || '#fff';
  const textboxBeforeBgImage =
    contentFields.textbox_bgimage_before &&
    contentFields.textbox_bgimage_before[0] &&
    contentFields.textbox_bgimage_before[0].fields &&
    contentFields.textbox_bgimage_before[0].fields.file &&
    contentFields.textbox_bgimage_before[0].fields.file.url;
  const textboxBeforeBgImageMobile =
    contentFields.textbox_bgimage_before &&
    contentFields.textbox_bgimage_before[1] &&
    contentFields.textbox_bgimage_before[1].fields &&
    contentFields.textbox_bgimage_before[1].fields.file &&
    contentFields.textbox_bgimage_before[1].fields.file.url;
  const posterImage =
    contentFields.poster &&
    contentFields.poster[0] &&
    contentFields.poster[0].fields &&
    contentFields.poster[0].fields.file &&
    contentFields.poster[0].fields.file.url;
  const posterImageMobile =
    contentFields.poster &&
    contentFields.poster[1] &&
    contentFields.poster[1].fields &&
    contentFields.poster[1].fields.file &&
    contentFields.poster[1].fields.file.url;
  const posterImageAlt =
    contentFields.poster &&
    contentFields.poster[0] &&
    contentFields.poster[0].fields &&
    contentFields.poster[0].fields.title;
  const textboxAfter = contentFields.textbox_after;
  const textboxAfterColor =
    contentFields.textbox_fontcolor_after === undefined || contentFields.textbox_fontcolor_after === true;
  const textboxAfterBgColor = contentFields.textbox_bgcolor_after || '#fff';
  const textboxAfterBgImage =
    contentFields.textbox_bgimage_after &&
    contentFields.textbox_bgimage_after[0] &&
    contentFields.textbox_bgimage_after[0].fields &&
    contentFields.textbox_bgimage_after[0].fields.file &&
    contentFields.textbox_bgimage_after[0].fields.file.url;
  const textboxAfterBgImageMobile =
    contentFields.textbox_bgimage_after &&
    contentFields.textbox_bgimage_after[1] &&
    contentFields.textbox_bgimage_after[1].fields &&
    contentFields.textbox_bgimage_after[1].fields.file &&
    contentFields.textbox_bgimage_after[1].fields.file.url;
  const legalText = contentFields.legals ? contentFields.legals.replace(/(<a [^>]*)(>)/gi, '$1 target="_blank"$2') : '';
  const newsletterBox = <NewsletterInput legals={legalText} variant="widget" touchpoint="cms_page" />;

  return (
    <React.Fragment>
      <Container className={styles.NewsletterContainer}>
        <InnerHTML
          className={[styles.HeaderHolder, !headlineAlign ? styles.AlignCenter : ''].join(' ')}
          html={headline}
        />
        {subHeadline ? (
          <InnerHTML
            className={[styles.SubHeaderHolder, !subHeadlineAlign ? styles.AlignCenter : ''].join(' ')}
            html={subHeadline}
          />
        ) : null}

        {textboxBefore ? (
          <div
            className={[
              styles.TextHolder,
              !textboxBeforeColor ? styles.WhiteText : '',
              textboxBeforeBgColor !== '#fff' ? styles.PaddingBox : '',
              textboxBeforeBgImage ? styles.BgImageBox : ''
            ].join(' ')}
            style={{ backgroundColor: `${textboxBeforeBgColor}` }}
          >
            {textboxBeforeBgImage ? (
              <CmsImageBg largeImage={textboxBeforeBgImage} smallImage={textboxBeforeBgImageMobile} />
            ) : null}
            <CmsRichtext text={textboxBefore} />
          </div>
        ) : null}

        <div className={styles.NewsletterWidget}>{newsletterBox}</div>

        {posterImage ? (
          <div className={styles.PosterHolder}>
            <CmsImage
              largeImage={posterImage}
              smallImage={posterImageMobile}
              imageAlt={posterImageAlt}
              source="CmsPageNewsletter.js"
              useHeight={false}
            />
          </div>
        ) : null}

        {textboxAfter ? (
          <div
            className={[
              styles.TextHolder,
              !textboxAfterColor ? styles.WhiteText : '',
              textboxAfterBgColor !== '#fff' ? styles.PaddingBox : '',
              textboxAfterBgImage ? styles.BgImageBox : ''
            ].join(' ')}
            style={{ backgroundColor: `${textboxAfterBgColor}` }}
          >
            {textboxAfterBgImage ? (
              <CmsImageBg largeImage={textboxAfterBgImage} smallImage={textboxAfterBgImageMobile} />
            ) : null}
            <CmsRichtext text={textboxAfter} />
          </div>
        ) : null}
      </Container>
    </React.Fragment>
  );
};

export default CmsPageNewsletter;
