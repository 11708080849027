import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import Container from '../../../layout/Container/Container';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { PromotionTracking } from '../../Tracking/GoogleTagManager';
import styles from './CmsModules.module.scss';
import { CmsImage } from './Components/CmsImages';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsHeadline } from './Components/CmsHeadline';
import { CmsRichtext } from './Components/CmsRichText';
import { CmsButton } from './Components/CmsButton';

const ElementThreeColumn = withStoreConfiguration(({ storeConfiguration, item, row }) => {
  const fontClasses = item.font_color === false ? [styles.FontColorSecondary] : [];
  const optionClasses =
    item.text_align === false ? [styles.TextAlign, styles.RowColumn, styles.Column] : [styles.RowColumn, styles.Column];
  const spaceClasses = item.bottom_space === true ? [styles.SpaceRemoval] : [];
  const isMobileSlider = !!item.slider_mobile;
  const wrapperBgColor = item.background_color ? item.background_color : '#ffffff';
  const count = 3;
  const columnsContent = [];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    breakpoints: {
      '(min-width: 460px)': {
        slides: { perView: 2, spacing: 20 }
      },
      '(min-width: 768px)': {
        slides: { perView: 3, spacing: 20 }
      }
    },
    slides: { perView: 1, spacing: 50 },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    }
  });

  for (let i = 1; i <= count; i++) {
    const columnTrackID = item[`tracking_${i}`];
    const columnImage = item[`image_${i}`] && item[`image_${i}`].length ? item[`image_${i}`][0] : null;
    const columnTarget = columnImage ? item[`image_${i}`][0].sys.id : null;
    const columnImageLarge = columnImage ? item[`image_${i}`][0].fields.file.url : null;
    const columnImageSmall = columnImage && item[`image_${i}`][1] ? item[`image_${i}`][1].fields.file.url : null;
    const columnImageAlt = columnImage ? item[`image_${i}`][0].fields : null;
    const columnImageCopyright = item[`image_copyright_${i}`] || null;
    const columnHeadlineType = item[`headline_type_${i}`];
    const columnHeadline = item[`headline_${i}`];
    const columnText = item[`text_${i}`];
    const columnSmallPrint = item[`small_print_${i}`];
    const columnTextFormat = item[`text_align_${i}`];
    const columnCta = item[`cta_${i}`];
    const columnCtaLinkAbsolute = item[`cta_link_${i}`] ? item[`cta_link_${i}`] : null;
    const columnCtaLinkRelative = item[`cta_link_reference_${i}`] ? item[`cta_link_reference_${i}`] : null;

    if (isMobileSlider) {
      columnsContent.push(
        <div className="keen-slider__slide" key={`column-${i}`}>
          <div className={optionClasses.join(' ')}>
            <>
              {columnImage ? (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  className={columnCtaLinkRelative || columnCtaLinkAbsolute ? 'Interactive' : null}
                  onClick={
                    columnCtaLinkRelative || columnCtaLinkAbsolute
                      ? () => document.getElementById(`${columnTarget}`).click()
                      : null
                  }
                >
                  <PromotionTracking
                    storeConfiguration={storeConfiguration}
                    trackingCategory={`Slider_${i}`}
                    trackingAction="click"
                    promotionName={item?.[`tracking_${i}`]?.[0]}
                    creativeName={item?.[`tracking_${i}`]?.[2]}
                    creativeSlot={i - 1}
                    trackingLabel="Homepage"
                  >
                    <CmsImage
                      largeImage={columnImageLarge}
                      smallImage={columnImageSmall}
                      imageAlt={columnImageAlt}
                      imgCopyright={columnImageCopyright}
                      useHeight={false}
                      row={row}
                      source="ElementThreeColumns.js"
                    />
                  </PromotionTracking>
                </div>
              ) : null}
              {columnHeadline ? <CmsHeadline headline={columnHeadline} headlineTag={columnHeadlineType} /> : null}
              {columnText ? (
                <CmsRichtext text={columnText} smallPrint={columnSmallPrint} textFormat={columnTextFormat} />
              ) : null}
            </>
            {columnCta ? (
              <CmsButton
                types={!columnHeadline && !columnText && !columnSmallPrint && columnImage ? 'invisible' : null}
                id={columnTarget}
                absoluteLink={columnCtaLinkAbsolute}
                relativeLink={columnCtaLinkRelative}
                buttonText={columnCta}
                tracking={columnTrackID}
              />
            ) : null}
          </div>
        </div>
      );
    } else {
      columnsContent.push(
        <React.Fragment key={`column-${i}`}>
          <div className={optionClasses.join(' ')}>
            <>
              {columnImage ? (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  className={columnCtaLinkRelative || columnCtaLinkAbsolute ? 'Interactive' : null}
                  onClick={
                    columnCtaLinkRelative || columnCtaLinkAbsolute
                      ? () => document.getElementById(`${columnTarget}`).click()
                      : null
                  }
                >
                  <CmsImage
                    largeImage={columnImageLarge}
                    smallImage={columnImageSmall}
                    imageAlt={columnImageAlt}
                    imgCopyright={columnImageCopyright}
                    source="ElementThreeColumn.js - slider"
                    useHeight={false}
                    row={row}
                  />
                </div>
              ) : null}
              {columnHeadline ? <CmsHeadline headline={columnHeadline} headlineTag={columnHeadlineType} /> : null}
              {columnText ? (
                <CmsRichtext text={columnText} smallPrint={columnSmallPrint} textFormat={columnTextFormat} />
              ) : null}
            </>
            {columnCta ? (
              <CmsButton
                types={!columnHeadline && !columnText && !columnSmallPrint && columnImage ? 'invisible' : null}
                id={columnTarget}
                absoluteLink={columnCtaLinkAbsolute}
                relativeLink={columnCtaLinkRelative}
                buttonText={columnCta}
                tracking={columnTrackID}
              />
            ) : null}
          </div>
        </React.Fragment>
      );
    }
  }

  return (
    <section
      style={{ backgroundColor: `${wrapperBgColor}` }}
      className={spaceClasses.join(' ')}
      data-cms-block="element_3_column"
    >
      <Container className={fontClasses.join(' ')}>
        <CmsRowHeadline item={item} bgColor={wrapperBgColor} />
        {isMobileSlider ? (
          <div className={styles.RowSlider}>
            <div className="navigation-wrapper">
              <div ref={sliderRef} className="keen-slider">
                {columnsContent}
              </div>
              {loaded && instanceRef.current && (
                <div className="dots ShowTablet ShowMobile element-column">
                  {[
                    ...Array(
                      instanceRef.current.track.details.slides.length - instanceRef.current.options.slides.perView + 1 >
                        1
                        ? instanceRef.current.track.details.slides.length -
                            instanceRef.current.options.slides.perView +
                            1
                        : 0
                    ).keys()
                  ].map(idx => {
                    return (
                      // eslint-disable-next-line react/button-has-type
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRef.current?.moveToIdx(idx);
                        }}
                        className={`dot${currentSlide === idx ? ' active' : ''}`}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.threeColumnRow}>{columnsContent}</div>
        )}
      </Container>
    </section>
  );
});

export default ElementThreeColumn;
