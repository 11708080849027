import React, { useState } from 'react';
import { I18n } from '@deity/falcon-i18n';
import { useKeenSlider } from 'keen-slider/react';
import Container from '../../../layout/Container/Container';
import { SearchBar } from '../../SearchBar/SearchBar';
import loadable from '../../../../../../components/loadable';
import { PromotionTracking } from '../../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './CmsModules.module.scss';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsTeaserBox } from './Components/CmsTeaserBox';
import { CmsHeadline } from './Components/CmsHeadline';
import { CmsImage } from './Components/CmsImages';
import { CmsVideo } from './Components/CmsVideo';
import { CmsRichtext } from './Components/CmsRichText';
import { CmsWidget } from './Components/CmsWidget';
import { CmsButton } from './Components/CmsButton';
import { CmsCfVideo } from './Components/CmsCfVideo';

const ElementProductFilter = loadable(() => import(/* webpackChunkName: "home" */ './ElementProductFilter'));

const HomeColumn = withStoreConfiguration(({ storeConfiguration, type, item, row }) => {
  const fontClasses = item.font_color === false ? [styles.FontColorSecondary] : [];
  const optionClasses = item.text_align === false ? [styles.TextAlign, styles.Column] : [styles.Column];
  const spaceClasses = item.bottom_space === true ? [styles.SpaceRemoval] : [];
  const columnsClass = [];
  const wrapperBgColor = item.background_color ? item.background_color : '#ffffff';
  const columnContentRow = item.column_content_row;
  const columnContentRowLength =
    columnContentRow && item.column_content_row.length ? item.column_content_row.length : null;

  let itemsByTypeLength = 0;
  let sliderMobile = false;
  const columnsContent = [];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    breakpoints: {
      '(min-width: 460px)': {
        slides: { perView: 2, spacing: 20 }
      },
      '(min-width: 768px)': {
        slides: { perView: 3, spacing: 20 }
      }
    },
    slides: { perView: 1 },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    }
  });

  switch (type) {
    case '1Column':
      itemsByTypeLength = 1;
      optionClasses.push(styles.ColumnFlexOff);
      columnsClass.push(styles.oneColumnRow);
      break;

    case '2Columns':
    case 'OneTwoColumn':
    case 'TwoOneColumn':
      itemsByTypeLength = 2;
      columnsClass.push(styles.twoColumnRow);

      if (type === 'TwoOneColumn') {
        columnsClass.push(styles.twoOneColumn);
      }

      if (type === 'OneTwoColumn') {
        columnsClass.push(styles.oneTwoColumn);
      }

      if (item.flip_mobile === true) {
        columnsClass.push(styles.twoColumnRowFlip);
      }

      if (item.break_mobile === false) {
        columnsClass.push(styles.twoColumnRowNoBreak);
      }
      break;

    case '3Columns':
      itemsByTypeLength = 3;
      sliderMobile = item.slider_mobile || false;
      optionClasses.push(styles.RowColumn);
      columnsClass.push(styles.threeColumnRow);
      break;

    case '4Columns':
      itemsByTypeLength = 4;
      columnsClass.push(styles.fourColumnRow);
      break;

    default:
      itemsByTypeLength = columnContentRowLength;
      break;
  }

  /* Test: update line below columnContentRowLength <-> itemsByTypeLength */
  for (let i = 0; i < itemsByTypeLength; i++) {
    const itemContentType = columnContentRow?.[i]?.sys?.contentType?.sys?.id;
    const itemTarget = columnContentRow?.[i]?.fields?.image?.[0]?.sys?.id || null;
    const itemTrackingID = columnContentRow?.[i]?.fields?.tracking;
    const itemHeadline = columnContentRow?.[i]?.fields?.headline;
    const itemHeadlineType = columnContentRow?.[i]?.fields?.headline_type;
    const itemHeadlinePosition = columnContentRow?.[i]?.fields?.headline_position;
    const itemText = columnContentRow?.[i]?.fields?.text;
    const itemSmallPrint = columnContentRow?.[i]?.fields?.small_print;
    const itemTextAlign = columnContentRow?.[i]?.fields?.text_align;
    const itemVideo = columnContentRow?.[i]?.fields?.video;
    const itemImage = columnContentRow?.[i]?.fields?.image || null;
    const itemImageAlt = columnContentRow?.[i]?.fields?.image?.[0]?.fields || null;
    const itemImageCopyright = columnContentRow?.[i]?.fields?.image_copyright;
    const itemLinkReference = columnContentRow?.[i]?.fields?.cta_link_reference || null;
    const itemRelatedProduct = columnContentRow?.[i]?.fields?.related_product || null;
    const itemRelatedProductLink = columnContentRow?.[i]?.fields?.related_product?.fields || null;
    const itemRelatedProductItem = columnContentRow?.[i]?.fields?.related_product?.fields?.products?.[0] || null;
    const itemCta = columnContentRow?.[i]?.fields?.cta ? columnContentRow?.[i]?.fields?.cta : false;
    const itemCtaLink = columnContentRow?.[i]?.fields?.cta_link || null;
    const itemWidget = columnContentRow?.[i]?.fields?.widget;
    const itemWidgetSource = columnContentRow?.[i]?.fields?.widget_source;

    const itemTeaserTitle = columnContentRow?.[i]?.fields?.title;
    const itemTeaserSubline = columnContentRow?.[i]?.fields?.subline;
    const itemTeaserLabelType = columnContentRow?.[i]?.fields?.label_type;

    const itemCfVideo = columnContentRow?.[i]?.fields?.cfVideo?.fields?.file?.url || null;
    const itemCfVideoTitle = columnContentRow?.[i]?.fields?.cfVideo?.fields?.title || null;
    const itemCfVideoThumb = columnContentRow?.[i]?.fields?.cfVideoThumb?.fields?.file?.url || null;
    const itemElProduct = columnContentRow?.[i]?.fields;

    let itemGeneratedContent = '';

    switch (itemContentType) {
      case 'home_teaser_box':
        itemGeneratedContent = (
          <I18n>
            {t => (
              <CmsTeaserBox
                title={itemTeaserTitle}
                subline={itemTeaserSubline}
                image={itemImage}
                relativeLink={itemLinkReference}
                absoluteLink={itemCtaLink}
                productLink={itemRelatedProductLink}
                labelType={itemTeaserLabelType}
                tracking={itemTrackingID}
                creativeSlot={i}
                product={itemRelatedProductItem}
                t={t}
                row={row}
              />
            )}
          </I18n>
        );
        break;
      case 'home_column_content':
        itemGeneratedContent = (
          <PromotionTracking
            storeConfiguration={storeConfiguration}
            product={itemRelatedProductItem}
            trackingCategory={itemTrackingID?.[0]}
            promotionName={itemTrackingID?.[0]}
            trackingAction={itemTrackingID?.[1]}
            trackingLabel={itemTrackingID?.[2]}
            creativeName={itemTrackingID?.[2]}
            creativeSlot={i}
          >
            <div className={optionClasses.join(' ')}>
              {itemWidget ? (
                <React.Fragment>
                  {itemWidgetSource !== 'Searchbar' ? (
                    <CmsWidget
                      itemContent={columnContentRow?.[i]}
                      widget={itemWidget}
                      widgetSource={itemWidgetSource}
                    />
                  ) : (
                    <SearchBar asWidget />
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div>
                    {itemHeadline && itemHeadlinePosition === true ? (
                      <CmsHeadline headline={itemHeadline} headlineTag={itemHeadlineType} />
                    ) : null}

                    {itemImage ? (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      <div
                        className={
                          itemCta && (itemLinkReference || itemRelatedProduct || itemCtaLink) ? 'Interactive' : null
                        }
                        onClick={
                          itemCta && (itemLinkReference || itemRelatedProduct || itemCtaLink)
                            ? () => document.getElementById(`${itemTarget}`).click()
                            : null
                        }
                      >
                        <CmsImage
                          largeImage={columnContentRow?.[i]?.fields?.image?.[0]?.fields?.file?.url}
                          smallImage={columnContentRow?.[i]?.fields?.image?.[1]?.fields?.file?.url}
                          imageAlt={itemImageAlt}
                          imgCopyright={itemImageCopyright}
                          useHeight={false}
                          row={row}
                          source="HomeColumn.js"
                          fetchPriority={row === 0}
                          aspectRatioLargeWidth={
                            columnContentRow?.[i]?.fields?.image?.[0]?.fields?.file?.details?.image?.width
                          }
                          aspectRatioLargeHeight={
                            columnContentRow?.[i]?.fields?.image?.[0]?.fields?.file?.details?.image?.height
                          }
                          aspectRatioSmallWidth={
                            columnContentRow?.[i]?.fields?.image?.[1]?.fields?.file?.details?.image?.width
                          }
                          aspectRatioSmallHeight={
                            columnContentRow?.[i]?.fields?.image?.[1]?.fields?.file?.details?.image?.height
                          }
                        />
                      </div>
                    ) : null}

                    {!itemImage && itemVideo ? (
                      <CmsVideo videoId={itemVideo} videoCopyright={itemImageCopyright} />
                    ) : null}

                    {itemCfVideo ? (
                      <CmsCfVideo videoUrl={itemCfVideo} videoThumb={itemCfVideoThumb} videoTitle={itemCfVideoTitle} />
                    ) : null}

                    {itemHeadline && itemHeadlinePosition !== true ? (
                      <CmsHeadline headline={itemHeadline} headlineTag={itemHeadlineType} />
                    ) : null}

                    {itemText ? (
                      <CmsRichtext text={itemText} smallPrint={itemSmallPrint} textFormat={itemTextAlign} />
                    ) : null}
                  </div>
                  {itemCta ? (
                    <I18n>
                      {t => (
                        <CmsButton
                          types={!itemHeadline && !itemText && !itemSmallPrint && itemImage ? 'invisible' : null}
                          id={itemTarget}
                          relativeLink={itemLinkReference}
                          absoluteLink={itemCtaLink}
                          productLink={itemRelatedProductLink}
                          buttonText={itemCta}
                          tracking={itemTrackingID}
                          t={t}
                        />
                      )}
                    </I18n>
                  ) : null}
                </React.Fragment>
              )}
            </div>
          </PromotionTracking>
        );
        break;
      case 'element_product_filter':
        itemGeneratedContent = (
          <ElementProductFilter
            item={itemElProduct}
            // slidesToScroll={type === 'OneTwoColumn' || type === 'TwoOneColumn' ? 3 : 2}
            slidesToShow={type === 'OneTwoColumn' || type === 'TwoOneColumn' ? 3 : 2}
            infinite
            columnIndex={i}
          />
        );
        break;
      default:
        return null;
    }

    const fragmentToPass = <React.Fragment key={`item-${i}`}>{itemGeneratedContent}</React.Fragment>;

    if (sliderMobile) {
      columnsContent.push(
        <div className="keen-slider__slide" key={`item-${i}`}>
          {fragmentToPass}
        </div>
      );
    } else {
      columnsContent.push(fragmentToPass);
    }
  }

  return (
    <section
      style={{ backgroundColor: `${wrapperBgColor}` }}
      className={spaceClasses.join(' ')}
      data-cms-block="home_column"
    >
      <Container className={fontClasses.join(' ')}>
        <CmsRowHeadline item={item} bgColor={wrapperBgColor} />
        {sliderMobile ? (
          <div className={styles.RowSlider}>
            <div className="navigation-wrapper">
              <div ref={sliderRef} className="keen-slider">
                {columnsContent}
              </div>
              {loaded && instanceRef.current && (
                <div className="dots ShowTablet ShowMobile element-column">
                  {[
                    ...Array(
                      instanceRef.current.track.details.slides.length - instanceRef.current.options.slides.perView + 1 >
                        1
                        ? instanceRef.current.track.details.slides.length -
                            instanceRef.current.options.slides.perView +
                            1
                        : 0
                    ).keys()
                  ].map(idx => {
                    return (
                      // eslint-disable-next-line react/button-has-type
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRef.current?.moveToIdx(idx);
                        }}
                        className={`dot${currentSlide === idx ? ' active' : ''}`}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={columnsClass.join(' ')}>{columnsContent}</div>
        )}
      </Container>
    </section>
  );
});

export default HomeColumn;
