import React from 'react';
import Container from '../../../layout/Container/Container';
import styles from './CmsModules.module.scss';
import additionalStyles from './Components/CmsSubstance.module.scss';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsImage } from './Components/CmsImages';

const ElementSubstances = ({ item }) => {
  const SubstancesContainerClasses = [];
  SubstancesContainerClasses.push(styles.oneColumnRow, additionalStyles.CmsSubstanceContainer);

  return (
    <section data-cms-block="element_substance">
      <Container>
        <CmsRowHeadline item={item} />
        <div className={SubstancesContainerClasses.join(' ')}>
          {item.substances.map((substancesItem, index) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions,react/no-array-index-key
            <React.Fragment key={`substances-${index}`}>
              {substancesItem.fields.link ? (
                <a href={substancesItem.fields.link} className={additionalStyles.CmsSubstanceItem}>
                  <CmsImage
                    largeImage={substancesItem.fields.image.fields.file.url}
                    smallImage={substancesItem.fields.image.fields.file.url}
                    imageAlt={substancesItem.fields.name}
                    useHeight={false}
                    source="ElementSubstances.js"
                  />
                  <p className={additionalStyles.CmsSubstanceItemName}>{substancesItem.fields.name}</p>
                </a>
              ) : (
                <div className={additionalStyles.CmsSubstanceItem}>
                  <CmsImage
                    largeImage={substancesItem.fields.image.fields.file.url}
                    smallImage={substancesItem.fields.image.fields.file.url}
                    imageAlt={substancesItem.fields.name}
                    useHeight={false}
                    source="ElementSubstances.js - 2"
                  />
                  <p className={additionalStyles.CmsSubstanceItemName}>{substancesItem.fields.name}</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ElementSubstances;
