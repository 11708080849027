import React, { useEffect } from 'react';
import { ContactForm } from '../../../../pages/Account/Help/ContactForm/ContactForm';
import { NewsletterInput } from '../../../../sections/Newsletter/NewsletterInput';
import loadable from '../../../../../../../components/loadable';
import ElementLastSeenProducts from '../ElementLastSeenProducts';
import { FacebookLive } from './CmsFacebookLive';
import { CmsCareer } from './CmsCareer';
import { CmsFrontnow } from './CmsFrontnow';

const BambuserPlayer = loadable(() =>
  import(/* webpackChunkName: "bambuserPlayer" */ '../../../Bambuser/BambuserPlayer')
);
const CmsMap = loadable(() => import(/* webpackChunkName: "cmsMap" */ './CmsMap'));
const Tellja = loadable(() => import(/* webpackChunkName: "cmsTellja" */ './CmsTellja'));

export const CmsWidget = ({ widget, widgetSource, itemContent }) => {
  let elementId, scriptUrl;

  useEffect(() => {
    switch (widget) {
      case 'Jobportal':
        elementId = 'jobportal';
        scriptUrl = 'https://stroeer.jobbase.io/widget/iframe.js?config=9ggj1cd3';
        break;
      case 'Adventskalender':
        elementId = 'adventskalender';
        scriptUrl = `https://${widgetSource}.online-adventskalender.de/embed/script/${widgetSource}`;
        break;
      case 'HappyContest':
        elementId = 'happycontest';
        scriptUrl = `https://${widgetSource}.hc-apps.de/embed/script/${widgetSource}`;
        break;
      default:
        return undefined;
    }

    const existingScript = document.getElementById(elementId);

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.async = true;
      script.id = elementId;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
        if (elementId === 'happycontest') {
          window.removeEventListener('resize', window.hc2SendParentWindowInfo);
        }
      };
    }
  }, []);

  switch (widget) {
    case 'Livestream':
      return <BambuserPlayer itemContent={itemContent} widgetSource={widgetSource} context="widget" />;
    case 'Jobportal':
      return <div id="psJobWidget" />;
    case 'Softgarden':
      return <CmsCareer />;
    case 'MüllerMap':
      return <CmsMap />;
    case 'ContactForm':
      return <ContactForm />;
    case 'FacebookLive':
      return <FacebookLive widgetSource={widgetSource} />;
    case 'Newsletter':
      return <NewsletterInput variant="widget" touchpoint="cms_widget" />;
    case 'Adventskalender':
    case 'HappyContest':
      return <a id="hc2-embed">Lädt ...</a>;
    case 'LastSeen':
      return <ElementLastSeenProducts />;
    case 'Tellja':
      return <Tellja />;
    case 'FrontnowSidebar':
    case 'FrontnowBanner':
    case 'FrontnowEmbedded':
      return <CmsFrontnow widget={widget} itemContent={itemContent} />;
    default:
      return null;
  }
};
