import React, { useState } from 'react';
import { CustomerQuery } from '../../../overrides/Customer/CustomerQuery';
import { LastSeenProducts } from '../../Product';
import styles from './CmsModules.module.scss';

const ElementLastSeenProducts = () => {
  const [show, setShow] = useState(true);
  return show ? (
    <div className={styles.LastSeenProductsWrapper}>
      <CustomerQuery>
        {({ data: { customer } }) => <LastSeenProducts customer={customer} setShow={setShow} />}
      </CustomerQuery>
    </div>
  ) : null;
};

export default ElementLastSeenProducts;
