import React from 'react';
import Container from '../../../layout/Container/Container';
import styles from './CmsModules.module.scss';
import { CmsImage } from './Components/CmsImages';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsHeadline } from './Components/CmsHeadline';
import { CmsRichtext } from './Components/CmsRichText';
import { CmsButton } from './Components/CmsButton';

const ElementInfoboxThreeColumn = ({ item, row }) => {
  const fontClasses = item.font_color === false ? [styles.FontColorSecondary] : [];
  const optionClasses =
    item.text_align === false
      ? [styles.TextAlign, styles.RowColumn, styles.Column, styles.infoBoxStyle]
      : [styles.RowColumn, styles.Column, styles.infoBoxStyle];
  const spaceClasses = item.bottom_space === true ? [styles.SpaceRemoval] : [];
  const wrapperBgColor = item.background_color ? item.background_color : '#ffffff';
  const count = 3;
  const columnsContent = [];

  for (let i = 1; i <= count; i++) {
    const columnTrackID = item[`tracking_${i}`];
    const columnImage = item[`image_${i}`] && item[`image_${i}`].length ? item[`image_${i}`][0] : null;
    const columnTarget = columnImage ? item[`image_${i}`][0].sys.id : null;
    const columnImageLarge = columnImage ? item[`image_${i}`][0].fields.file.url : null;
    const columnImageSmall = columnImage && item[`image_${i}`][1] ? item[`image_${i}`][1].fields.file.url : null;
    const columnImageAlt = columnImage ? item[`image_${i}`][0].fields : null;
    const columnImageCopyright = item[`image_copyright_${i}`] || null;
    const columnHeadlineType = item[`headline_type_${i}`];
    const columnHeadline = item[`headline_${i}`];
    const columnText = item[`text_${i}`];
    const columnTextFormat = item[`text_align_${i}`];
    const columnCta = item[`cta_${i}`];
    const columnCtaLinkAbsolute = item[`cta_link_${i}`] ? item[`cta_link_${i}`] : null;
    const columnBgColor = item[`bgColorBox_${i}`] ? item[`bgColorBox_${i}`] : '#ffffff';

    columnsContent.push(
      <React.Fragment key={`column-${i}`}>
        <div className={optionClasses.join(' ')} style={{ backgroundColor: `${columnBgColor}` }}>
          <>
            {columnImage ? (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className={columnCtaLinkAbsolute ? 'Interactive' : null}
                onClick={columnCtaLinkAbsolute ? () => document.getElementById(`${columnTarget}`).click() : null}
              >
                <CmsImage
                  largeImage={columnImageLarge}
                  smallImage={columnImageSmall}
                  imageAlt={columnImageAlt}
                  imgCopyright={columnImageCopyright}
                  source="ElementInfoboxThreeColumn.js"
                  useHeight={false}
                  row={row}
                />
              </div>
            ) : null}
            {columnHeadline ? <CmsHeadline headline={columnHeadline} headlineTag={columnHeadlineType} /> : null}
            {columnText ? <CmsRichtext text={columnText} textFormat={columnTextFormat} /> : null}
          </>
          {columnCta ? (
            <CmsButton
              types={!columnHeadline && !columnText && columnImage ? 'invisible' : null}
              id={columnTarget}
              absoluteLink={columnCtaLinkAbsolute}
              buttonText={columnCta}
              tracking={columnTrackID}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }

  return (
    <section
      style={{ backgroundColor: `${wrapperBgColor}` }}
      className={spaceClasses.join(' ')}
      data-cms-block="infobox_3_column"
    >
      <Container className={fontClasses.join(' ')}>
        <CmsRowHeadline item={item} bgColor={wrapperBgColor} />
        <div className={styles.threeColumnRow}>{columnsContent}</div>
      </Container>
    </section>
  );
};

export default ElementInfoboxThreeColumn;
