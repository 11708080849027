import React from 'react';
import loadable from '../../../../../components/loadable';
import { isAppMobile } from '../../helpers/AppMobile/AppMobile';

const HeroSlider = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ '../HeroSlider/HeroSlider'));
const HomeColumn = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/HomeColumn'));
const HomeSlideColumn = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/HomeSlideColumn'));
const HomeListColumn = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/HomeListColumn'));
const ElementAccordion = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementAccordion'));
const BambuserPlayer = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ '../Bambuser/BambuserPlayer'));
const ElementOneColumn = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementOneColumn'));
const ElementTwoColumn = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementTwoColumn'));
const ElementThreeColumn = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementThreeColumn'));
const ElementFourColumn = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementFourColumn'));
const ElementInfoboxOneColumn = loadable(() =>
  import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementInfoboxOneColumn')
);
const ElementInfoboxTwoColumn = loadable(() =>
  import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementInfoboxTwoColumn')
);
const ElementInfoboxThreeColumn = loadable(() =>
  import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementInfoboxThreeColumn')
);
const ElementInfoboxFourColumn = loadable(() =>
  import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementInfoboxFourColumn')
);
const ElementFullSizeColumn = loadable(() =>
  import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementFullSizeColumn')
);
const ElementSubstances = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementSubstances'));
const ElementProductFilter = loadable(() =>
  import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementProductFilter')
);
const TagNavigation = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/TagNavigation'));
const CmsQuote = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/Components/CmsQuote'));
const ElementQA = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/ElementQA'));
const CatalogProduct = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/CatalogProduct'));
const CmsPageNewsletter = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/CmsPageNewsletter'));
const CountDown = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/CountDown'));
const AuthorBox = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ './Modules/AuthorBox'));

const Row = ({ contentType, item, row }) => {
  switch (contentType) {
    case 'element_1_column':
      return <ElementOneColumn item={item} row={row} />;
    case 'element_2_columns':
      return <ElementTwoColumn item={item} row={row} />;
    case 'element_3_columns':
      return <ElementThreeColumn item={item} row={row} />;
    case 'element_4_columns':
      return <ElementFourColumn item={item} row={row} />;
    case 'element_fullsize_column':
      return <ElementFullSizeColumn item={item} row={row} />;
    case 'infobox_1_column':
      return <ElementInfoboxOneColumn item={item} row={row} />;
    case 'infobox_2_columns':
      return <ElementInfoboxTwoColumn item={item} row={row} />;
    case 'infobox_3_columns':
      return <ElementInfoboxThreeColumn item={item} row={row} />;
    case 'infobox_4_columns':
      return <ElementInfoboxFourColumn item={item} row={row} />;
    case 'element_accordion':
      return <ElementAccordion item={item} />;
    case 'element_substances':
      return <ElementSubstances item={item} />;
    case 'element_product_filter':
      return <ElementProductFilter item={item} />;
    case 'catalog_products_quotes':
      return <CmsQuote item={item} />;
    case 'element_tagnavigation':
      return isAppMobile ? <div /> : <TagNavigation item={item} />;
    case 'element_qa':
      return <ElementQA item={item} />;
    case 'catalog_product':
      return <CatalogProduct item={item} path={item.slug || null} id={item.sku || null} />;
    case 'widget_content':
      return <CmsPageNewsletter item={item} />;
    case 'element_live_badge':
      return <BambuserPlayer itemContent={item} context="badge" />;
    case 'countDown':
      return <CountDown itemContent={item} />;
    case 'author_box':
      return <AuthorBox item={item} />;
    case 'home_stage':
      return item.stage_slides ? (
        <HeroSlider banners={item.stage_slides} autoplay={item.autoplay === false ? item.autoplay : true} />
      ) : null;
    default:
      break;
  }

  if (contentType === 'home_columns' && item?.column_content_row?.length) {
    switch (item.column_type) {
      case 'Slider':
        return <HomeSlideColumn item={item} row={row} />;
      case 'List':
        return <HomeListColumn item={item} row={row} />;
      default:
        return <HomeColumn type={item.column_type} item={item} row={row} />;
    }
  }
  return null;
};

export default Row;
