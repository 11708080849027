import React, { useState } from 'react';
import { I18n } from '@deity/falcon-i18n';
import { Helmet } from 'react-helmet-async';
import { InnerHTML } from '@deity/falcon-ui-kit';
import { useKeenSlider } from 'keen-slider/react';
import { Labels } from '../Labels';
import { CmsButton } from '../Cms/Modules/Components/CmsButton';
import { CmsImage } from '../Cms/Modules/Components/CmsImages';
import { PromotionTracking } from '../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import useCheckBreakpoints from '../../hooks/CheckBreakpoint';
import styles from './HeroSlider.module.scss';

const HeroSlider = withStoreConfiguration(({ storeConfiguration, banners, autoplay }) => {
  const { isMobile } = useCheckBreakpoints();
  const checkAutoplay = banners.length > 1 ? autoplay : false;
  const loopStatus = banners.length > 1;

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      loop: loopStatus,
      // drag: false,
      slides: {
        perView: 1
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      }
    },
    [
      slider => {
        if (checkAutoplay) {
          const timeOffset = 5000;
          let timeout;
          let mouseOver = false;

          const clearNextTimeout = () => {
            clearTimeout(timeout);
          };

          const nextTimeout = () => {
            clearTimeout(timeout);
            if (mouseOver) return;
            timeout = setTimeout(() => {
              slider.next();
            }, timeOffset);
          };

          slider.on('created', () => {
            slider.container.addEventListener('mouseover', () => {
              mouseOver = true;
              clearNextTimeout();
            });
            slider.container.addEventListener('mouseout', () => {
              mouseOver = false;
              nextTimeout();
            });
            nextTimeout();
          });
          slider.on('dragStarted', clearNextTimeout);
          slider.on('animationEnded', nextTimeout);
          slider.on('updated', nextTimeout);
        }
      }
    ]
  );

  const Arrow = props => {
    if (banners.length > 1) {
      return (
        <button
          type="button"
          onClick={props.onClick}
          className={[
            styles.Arrow,
            props.left
              ? [styles.ArrowPrev, 'icon-hero-left'].join(' ')
              : [styles.ArrowNext, 'icon-hero-right'].join(' ')
          ].join(' ')}
          aria-label={props.left ? 'prev' : 'next'}
        />
      );
    }

    return null;
  };

  const displayClass = nr => {
    const displayClasses = [];

    switch (banners[nr].fields.align) {
      case 'left':
        displayClasses.push(styles.HeroSliderLeft);
        break;
      case 'right':
        displayClasses.push(styles.HeroSliderRight);
        break;
      case 'center':
        displayClasses.push(styles.HeroSliderCenter);
        break;
      default:
        displayClasses.push(styles.HeroSliderLeft);
    }

    switch (banners[nr].fields.title_color) {
      case 'beige':
        displayClasses.push(styles.HeroSliderTitleColorBeige);
        break;
      case 'grey':
        displayClasses.push(styles.HeroSliderTitleColorGrey);
        break;
      case 'white':
        displayClasses.push(styles.HeroSliderTitleColorWhite);
        break;
      case 'purple':
        displayClasses.push(styles.HeroSliderTitleColorPurple);
        break;
      default:
        displayClasses.push(styles.HeroSliderTitleColorBlack);
    }

    switch (banners[nr].fields.subtitle_form) {
      case false:
        displayClasses.push(styles.HeroSliderSubtitleItalic);
        break;
      default:
        displayClasses.push(styles.HeroSliderSubtitleNormal);
    }

    if (banners[nr].fields.button_mobile_bottom) {
      displayClasses.push(styles.buttonMobileBottom);
    }

    if (banners[nr].fields.button_mobile_hide) {
      displayClasses.push(styles.buttonMobileHide);
    }

    return [...displayClasses].join(' ');
  };

  const subtitleString = item => {
    return (
      <>
        {!isMobile && item.fields.subtitle}
        {isMobile && !item.fields.mobile_points && (item.fields.mobile_subtitle || item.fields.subtitle)}
      </>
    );
  };

  return banners.length >= 1 ? (
    <I18n>
      {t => (
        <div className={styles.HeroSlider}>
          <div className="navigation-wrapper">
            <div ref={sliderRef} className="keen-slider">
              {banners.slice(0, 4).map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="keen-slider__slide" key={`slide-'${index}'`}>
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <div
                    onClick={
                      item.fields.cta_link_reference || item.fields.related_product || item.fields.cta_link
                        ? () => document.getElementById(`${item.fields.image[0].sys.id}`).click()
                        : null
                    }
                    className={[
                      item.fields.cta_link_reference || item.fields.related_product || item.fields.cta_link
                        ? 'Interactive'
                        : null,
                      styles.HeroSliderBackground,
                      displayClass(index)
                    ].join(' ')}
                  >
                    <div className={styles.HeroSliderContent}>
                      <PromotionTracking
                        storeConfiguration={storeConfiguration}
                        product={
                          item.fields.related_product &&
                          item.fields.related_product.fields &&
                          item.fields.related_product.fields.products
                            ? item.fields.related_product.fields.products
                            : null
                        }
                        trackingCategory={`Slider_${index}`}
                        trackingAction="click"
                        promotionName={item?.fields?.tracking?.[0]}
                        creativeName={item?.fields?.tracking?.[2]}
                        creativeSlot={index}
                        trackingLabel="Homepage"
                      >
                        {item?.fields?.related_product?.fields?.products?.[0]?.price?.regular ? (
                          <div className={styles.HeroSliderLabel}>
                            <Labels
                              type="purple"
                              size="large"
                              price={item.fields.related_product.fields.products[0].price.regular}
                              specialPrice={
                                item.fields.related_product.fields.products[0].price.special
                                  ? item.fields.related_product.fields.products[0].price.special
                                  : null
                              }
                              pricePerUnit={item.fields.related_product.fields.products[0].baseprice}
                              rotated
                              transparent
                              product={item.fields.related_product.fields.products[0]}
                              cms
                            />
                          </div>
                        ) : null}

                        <div
                          className={[
                            styles.HeroSliderTitle,
                            item.fields.title_form ? styles.HeroSliderTitleNormal : null,
                            item.fields.title_size ? styles.HeroSliderTitleXL : null
                          ].join(' ')}
                        >
                          {item.fields.title}
                        </div>

                        <div
                          className={[
                            styles.HeroSliderSubtitle,
                            item.fields.subtitle_mobile ? 'HideMobile' : null
                          ].join(' ')}
                        >
                          {subtitleString(item)}
                        </div>
                        {item.fields.points && (
                          <InnerHTML
                            className={[styles.HeroSliderPoints, 'HideMobile'].join(' ')}
                            html={item.fields.points}
                          />
                        )}
                        {item.fields.mobile_points && (
                          <InnerHTML
                            className={[styles.HeroSliderPoints, 'ShowMobile'].join(' ')}
                            html={item.fields.mobile_points}
                          />
                        )}
                        {item.fields.coupon_code && (
                          <span className={styles.HeroSliderCouponCode}>{item.fields.coupon_code}</span>
                        )}
                        {(item.fields.cta_link_reference || item.fields.related_product || item.fields.cta_link) && (
                          <div className="HeroSliderButton">
                            <CmsButton
                              types={!item.fields.cta || item.fields.coupon_code ? 'invisible' : 'default'}
                              id={item.fields.image[0].sys.id}
                              relativeLink={item.fields.cta_link_reference ? item.fields.cta_link_reference : null}
                              absoluteLink={item.fields.cta_link ? item.fields.cta_link : null}
                              productLink={item.fields.related_product ? item.fields.related_product.fields : null}
                              buttonText={item.fields.cta}
                              tracking={item.fields.tracking}
                              t={t}
                            />
                          </div>
                        )}
                      </PromotionTracking>
                    </div>
                    {item.fields.image_copyright && (
                      <small className={styles.HeroSliderCopyright}>{item.fields.image_copyright}</small>
                    )}
                    {/* Preload only first image to boost performance */}
                    {index === 0 && item.fields.image && (
                      <Helmet>
                        <link
                          rel="preload"
                          href={`${item.fields.image[0].fields.file.url}?fm=webp`}
                          imageSrcSet={
                            item.fields.image[1]
                              ? `${item.fields.image[0].fields.file.url}?fm=webp 1x, ${item.fields.image[1].fields.file.url}?fm=webp 2x`
                              : `${item.fields.image[0].fields.file.url}?fm=webp 1x`
                          }
                          as="image"
                        />
                      </Helmet>
                    )}
                    {item.fields.image && (
                      <div className={styles.HeroSliderImg} data-index={index}>
                        <CmsImage
                          largeImage={item.fields.image[0].fields.file.url}
                          smallImage={
                            item.fields.image[1]
                              ? item.fields.image[1].fields.file.url
                              : item.fields.image[0].fields.file.url
                          }
                          imageAlt={item.fields.image[0].fields}
                          source="HeroSlider.js"
                          useHeight={false}
                          forceLoad
                          largeImageWidth={item.fields?.image?.[0]?.fields?.file?.details?.image?.width}
                          largeImageHeight={item.fields?.image?.[0]?.fields?.file?.details?.image?.height}
                          smallImageWidth={
                            item.fields?.image?.[1] ? item.fields.image?.[1]?.fields?.file?.details?.image?.width : null
                          }
                          smallImageHeight={
                            item.fields?.image?.[1]
                              ? item.fields.image?.[1]?.fields?.file?.details?.image?.height
                              : null
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {loaded && instanceRef.current && (
              <>
                <Arrow
                  left
                  onClick={e => {
                    instanceRef.current?.prev();
                    e.stopPropagation();
                  }}
                />

                <Arrow
                  onClick={e => {
                    instanceRef.current?.next();
                    e.stopPropagation();
                  }}
                />
              </>
            )}
            {loaded && instanceRef.current && (
              <div className="dots">
                {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                  return (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <span
                      key={idx}
                      data-index={idx}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(idx);
                      }}
                      className={`dot${currentSlide === idx ? ' active' : ''}`}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </I18n>
  ) : null;
});

export default HeroSlider;
