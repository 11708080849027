import React from 'react';
import Container from '../../../layout/Container/Container';
import styles from './CmsModules.module.scss';
import { CmsImage } from './Components/CmsImages';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsHeadline } from './Components/CmsHeadline';
import { CmsRichtext } from './Components/CmsRichText';
import { CmsButton } from './Components/CmsButton';
import { CmsVideo } from './Components/CmsVideo';
import { CmsCfVideo } from './Components/CmsCfVideo';

const ElementTwoColumn = ({ item, row }) => {
  const wrapperBgColor = item.background_color ? item.background_color : '#ffffff';
  const fontClasses = item.font_color === false ? [styles.FontColorSecondary] : [];
  const optionClasses = item.text_align === false ? [styles.TextAlign, styles.Column] : [styles.Column];
  const spaceClasses = item.bottom_space === true ? [styles.SpaceRemoval] : [];
  const twoColumnClasses =
    item.flip_mobile === true &&
    item.special_options_1 !== 'Hide Column Mobile' &&
    item.special_options_2 !== 'Hide Column Mobile'
      ? [styles.twoColumnRow, styles.twoColumnRowFlip]
      : [styles.twoColumnRow];
  const count = 2;
  const columnContent = [];

  if (item.align_button === false) {
    optionClasses.push(styles.ColumnFlexOff);
  }

  if (item.break_mobile === false) {
    twoColumnClasses.push(styles.twoColumnRow, styles.twoColumnRowNoBreak);
  }

  if (item.center_content === true) {
    twoColumnClasses.push(styles.HorizontalAlign);
  }

  for (let i = 1; i <= count; i++) {
    const columnVideo = item[`video_${i}`] ? item[`video_${i}`] : null;
    const columnImage = item[`image_${i}`] && item[`image_${i}`].length ? item[`image_${i}`][0] : null;
    const columnTarget = columnImage ? item[`image_${i}`][0].sys.id : null;
    const columnImageLarge = columnImage ? item[`image_${i}`][0].fields.file.url : null;
    const columnImageSmall = columnImage && item[`image_${i}`][1] ? item[`image_${i}`][1].fields.file.url : null;
    const columnImageAlt = columnImage ? item[`image_${i}`][0].fields : null;
    const columnImageCopyright = item[`image_copyright_${i}`];
    const columnIcon = item[`icon_${i}`] ? item[`icon_${i}`].fields.file.url : null;
    const columnHeadlineType = item[`headline_type_${i}`];
    const columnHeadline = item[`headline_${i}`];
    const columnText = item[`text_${i}`];
    const columnSmallPrint = item[`small_print_${i}`];
    const columnTextFormat = item[`text_align_${i}`];
    const columnCta = item[`cta_${i}`];
    const columnCtaLinkAbsolute = item[`cta_link_${i}`] ? item[`cta_link_${i}`] : null;
    const columnCtaLinkRelative = item[`cta_link_reference_${i}`] ? item[`cta_link_reference_${i}`] : null;
    const columnTrackID = item[`tracking_${i}`];
    const hideOnMobile = item[`special_options_${i}`] === 'Hide Image Mobile' ? true : null;
    const specialOptionHideOnMobile =
      item[`special_options_${i}`] === 'Hide Column Mobile' ? 'HideMobile' : optionClasses.join(' ');
    const specialOptionTextAboveContainer =
      item[`special_options_${i}`] === 'Place Text Above Image' ? styles.TextAboveImageContainer : null;
    const specialOptionTextAbove =
      item[`special_options_${i}`] === 'Place Text Above Image' ? styles.TextAboveImage : null;
    const columnCfVideo = item[`cfVideo_${i}`]?.fields?.file?.url || null;
    const columnCfVideoTitle = item[`cfVideo_${i}`]?.fields?.title || null;
    const columnCfVideoThumb = item[`cfVideoThumb_${i}`]?.fields?.file?.url || null;

    columnContent.push(
      <React.Fragment key={`column-${i}`}>
        <div className={specialOptionHideOnMobile}>
          <div className={specialOptionTextAboveContainer}>
            {columnImage ? (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className={columnCtaLinkRelative || columnCtaLinkAbsolute ? 'Interactive' : null}
                onClick={
                  columnCtaLinkRelative || columnCtaLinkAbsolute
                    ? () => document.getElementById(`${columnTarget}`).click()
                    : null
                }
              >
                <CmsImage
                  hideMobile={hideOnMobile}
                  largeImage={columnImageLarge}
                  smallImage={columnImageSmall}
                  imageAlt={columnImageAlt}
                  imgCopyright={columnImageCopyright}
                  source="ElementTwoColumn.js"
                  useHeight={false}
                  row={row}
                />
              </div>
            ) : null}
            {!columnImage && columnVideo ? (
              <CmsVideo videoId={columnVideo} videoCopyright={columnImageCopyright} />
            ) : null}
            {columnCfVideo ? (
              <CmsCfVideo videoUrl={columnCfVideo} videoThumb={columnCfVideoThumb} videoTitle={columnCfVideoTitle} />
            ) : null}
            {columnHeadline ? <CmsHeadline headline={columnHeadline} headlineTag={columnHeadlineType} /> : null}
            {columnText ? (
              <div className={specialOptionTextAbove}>
                {columnIcon ? (
                  <div className={styles.IconAboveText}>
                    <CmsImage largeImage={columnIcon} smallImage={columnIcon} source="ElementTwoColumns.js - 2" />{' '}
                  </div>
                ) : null}
                {columnText ? (
                  <CmsRichtext text={columnText} smallPrint={columnSmallPrint} textFormat={columnTextFormat} />
                ) : null}
              </div>
            ) : null}
          </div>
          {columnCta ? (
            <CmsButton
              types={!columnHeadline && !columnText && !columnSmallPrint && columnImage ? 'invisible' : null}
              id={columnTarget}
              absoluteLink={columnCtaLinkAbsolute}
              relativeLink={columnCtaLinkRelative}
              buttonText={columnCta}
              tracking={columnTrackID}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }

  return (
    <section
      style={{ backgroundColor: `${wrapperBgColor}` }}
      className={spaceClasses.join(' ')}
      data-cms-block="element_2_column"
    >
      <Container className={fontClasses.join(' ')}>
        <CmsRowHeadline item={item} bgColor={wrapperBgColor} />
        <div className={twoColumnClasses.join(' ')}>{columnContent}</div>
      </Container>
    </section>
  );
};

export default ElementTwoColumn;
