import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from '../../../Badge';
import { Labels } from '../../../Labels';
import { createRelativeStoreUrl, isExternalLink } from '../../../../helpers/Url/RelativeUrl';
import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';
import { PromotionTracking } from '../../../Tracking/GoogleTagManager';
import styles from './CmsTeaserBox.module.scss';
import { CmsImage } from './CmsImages';

export const CmsTeaserBox = withStoreConfiguration(
  ({
    storeConfiguration,
    title,
    subline,
    absoluteLink,
    relativeLink,
    labelType,
    tracking,
    creativeSlot,
    image,
    product,
    productLink,
    t,
    row
  }) => {
    const processedLink = [];

    let external = false;

    if (absoluteLink) {
      processedLink.push(absoluteLink);
    } else if (
      !absoluteLink &&
      productLink &&
      productLink.products &&
      productLink.products[0] &&
      productLink.products[0].storeUrls
    ) {
      const productUrl = productLink.products[0].storeUrls
        .filter(store => store.storeCode === t('domain.storeCode'))
        .map(store => {
          return store.url;
        });
      processedLink.push(productUrl[0]);
    } else if (!absoluteLink && !product && relativeLink && relativeLink.fields && relativeLink.fields.url) {
      processedLink.push(relativeLink.fields.url);
    }

    if (isExternalLink(processedLink[0], storeConfiguration)) {
      external = true;
    }

    if (processedLink && processedLink[0] && !isExternalLink(processedLink[0], storeConfiguration)) {
      processedLink[0] = createRelativeStoreUrl(processedLink[0]);
    }

    return (
      <PromotionTracking
        storeConfiguration={storeConfiguration}
        product={product}
        trackingCategory={tracking && tracking[0] ? tracking[0] : null}
        promotionName={tracking && tracking[0] ? tracking[0] : null}
        trackingAction={tracking && tracking[1] ? tracking[1] : null}
        trackingLabel={tracking && tracking[2] ? tracking[2] : null}
        creativeName={tracking && tracking[2] ? tracking[2] : null}
        creativeSlot={creativeSlot}
      >
        <div className={[styles.CmsTeaserBoxWrapper, 'Item'].join(' ')}>
          <div className={styles.CmsTeaserLabelWrapper}>
            {product && product.price && labelType === true ? (
              <div className={styles.CmsTeaserPriceWrapper}>
                <Labels
                  type="purple"
                  size="normal"
                  price={product.price.regular}
                  specialPrice={product.price.special ? product.price.special : null}
                  pricePerUnit={product.baseprice}
                  product={product}
                  rotated
                  transparent
                  cms
                />
              </div>
            ) : (
              product &&
              product.labels &&
              product.labels
                .filter(item => !item.badge === true)
                .slice(0, 2)
                .map((item, index) => {
                  return (
                    <Labels
                      key={`label-${index}`} // eslint-disable-line
                      type={item.type}
                      size={item.size}
                      rotated={item.rotated}
                      transparent={item.transparent}
                      percent={item.percent}
                      text={item.text}
                      product={product}
                    />
                  );
                })
            )}
          </div>
          <div className={styles.CmsTeaserBoxContent}>
            {product &&
              product.labels &&
              product.labels
                .filter(item => item.badge === true)
                .slice(0, 1)
                .map((item, index) => {
                  // eslint-disable-next-line react/no-array-index-key
                  return <Badge key={`badge-${index}`} text={item.text} type={item.type} size="large" />;
                })}
            {title ? <h3 className={styles.CmsTeaserBoxTitle}>{title}</h3> : null}
            {subline ? <p className={styles.CmsTeaserBoxSubline}>{subline}</p> : null}
          </div>
          {image ? (
            <CmsImage
              largeImage={image[0] && image[0].fields && image[0].fields.file ? image[0].fields.file.url : null}
              smallImage={image[1] && image[1].fields && image[1].fields.file ? image[1].fields.file.url : null}
              row={row}
              useWidth={false}
              imageAlt={image[0] ? image[0].fields : null}
              source="CmsTeaserBox.js"
              largeImageWidth={image?.[0] ? image[0]?.fields?.file?.details?.image?.width : null}
              largeImageHeight={image?.[0] ? image[0]?.fields?.file?.details?.image?.height : null}
              smallImageWidth={image?.[1] ? image[1]?.fields?.file?.details?.image?.width : null}
              smallImageHeight={image?.[1] ? image[1]?.fields?.file?.details?.image?.height : null}
            />
          ) : null}

          {processedLink.length ? (
            <React.Fragment>
              {external ? (
                <a
                  href={processedLink[0]}
                  rel={external ? 'noopener noreferrer nofollow' : null}
                  target={external ? '_blank' : null}
                  data-tracking-category={tracking && tracking[0] ? tracking[0] : null}
                  data-tracking-action={tracking && tracking[1] ? tracking[1] : null}
                  data-tracking-label={tracking && tracking[2] ? tracking[2] : null}
                  className={styles.WholeLink}
                >
                  {title || 'read more'}
                </a>
              ) : (
                <Link
                  to={processedLink[0]}
                  data-tracking-category={tracking && tracking[0] ? tracking[0] : null}
                  data-tracking-action={tracking && tracking[1] ? tracking[1] : null}
                  data-tracking-label={tracking && tracking[2] ? tracking[2] : null}
                  className={styles.WholeLink}
                >
                  {title || 'read more'}
                </Link>
              )}
            </React.Fragment>
          ) : null}
        </div>
      </PromotionTracking>
    );
  }
);
