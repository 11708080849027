import React, { useEffect } from 'react';
import { forceCheck } from 'react-lazyload';
import { CmsImage } from './Components/CmsImages';
import { CmsButton } from './Components/CmsButton';

function onLoadFunction() {
  const mainContent = document.getElementsByClassName('MainContent')[0];
  const pageHead = document.getElementsByClassName('FullSizeColumnRow')[0];

  if (mainContent) {
    mainContent.prepend(pageHead);
  }
}

const ElementFullSizeColumn = ({ item, row }) => {
  if (item.is_header === true) {
    useEffect(() => {
      onLoadFunction();
      forceCheck();
    }, []);
  }

  const ctaText = item.cta;
  const ctaLink = item.cta_link ? item.cta_link : null;
  const itemImage = item.image && item.image.length ? item.image[0] : null;
  const target = itemImage ? item.image[0].sys.id : null;
  const itemImageLarge = itemImage ? item.image[0].fields.file.url : null;
  const itemImageSmall = itemImage && item.image[1] ? item.image[1].fields.file.url : null;
  const itemImageAlt = itemImage ? item.image[0].fields : null;

  return (
    <section className="FullSizeColumnRow" data-cms-block="element_full_size_column">
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={ctaLink ? 'Interactive' : null}
        onClick={ctaLink ? () => document.getElementById(`${target}`).click() : null}
      >
        <CmsImage largeImage={itemImageLarge} smallImage={itemImageSmall} imageAlt={itemImageAlt} row={row} />
      </div>
      {ctaText && ctaLink && itemImage ? (
        <CmsButton types="invisible" id={target} absoluteLink={ctaLink} buttonText={ctaText} />
      ) : null}
    </section>
  );
};

export default ElementFullSizeColumn;
