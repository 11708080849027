import React from 'react';
import Countdown from 'react-countdown';
import { I18n } from '@deity/falcon-i18n';
import { Link } from 'react-router-dom';
import { InnerHTML } from '@deity/falcon-ui-kit';
import Container from '../../../layout/Container/Container';
import styles from './CountDown.module.scss';

const CountDown = ({ itemContent }) => {
  const { headline, subHeadline, headlinePosition, endDate, textColor, bgColor, url, position } = itemContent;
  const cssClassPosition = position ? styles.PositionOverlay : styles.PositionStatic;
  const cssClassHeadline = headlinePosition ? styles.DisplayInline : null;
  const inlineStyles = {
    backgroundColor: `${bgColor}`,
    color: `${textColor}`
  };

  const HeadlineRender = () => {
    return headline ? (
      <h4 className={[styles.Headline, cssClassHeadline].join(' ')}>
        <strong>{headline}</strong> {subHeadline ? ` - ${subHeadline}` : null}
      </h4>
    ) : null;
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    }
    return (
      <I18n>
        {t => (
          <section data-cms-block="countDown" className={cssClassPosition}>
            <Container>
              <div className={styles.WidgetWrapper} style={inlineStyles}>
                {headlinePosition ? <HeadlineRender /> : null}
                <div className={styles.TimeHolder}>
                  {`0${days}`.slice(-2)}{' '}
                  {days !== 1 ? <InnerHTML html={t('countDown.days')} /> : <InnerHTML html={t('countDown.day')} />}{' '}
                  {`0${hours}`.slice(-2)} <InnerHTML html={t('countDown.hours')} /> {`0${minutes}`.slice(-2)}{' '}
                  <InnerHTML html={t('countDown.minutes')} /> {`0${seconds}`.slice(-2)}{' '}
                  <InnerHTML html={t('countDown.seconds')} />
                </div>
                {headlinePosition ? null : <HeadlineRender />}
                {url ? (
                  <Link className={styles.WidgetLink} to={url}>
                    more...
                  </Link>
                ) : null}
              </div>
            </Container>
          </section>
        )}
      </I18n>
    );
  };

  return <Countdown date={endDate} zeroPadTime={2} zeroPadDays={2} renderer={renderer} />;
};

export default CountDown;
