import { PageviewTracking } from './Pageview';

export const FrontnowTracking = ({ storeConfiguration, eventName }) => {
  const dataLayerData = {
    key: `frontnow_${eventName}`,
    event: 'Generic Event',
    event_name: `${eventName}`
  };

  return PageviewTracking({ storeConfiguration, dataLayerData });
};
