import React from 'react';
import Container from '../../../layout/Container/Container';
import styles from './CmsModules.module.scss';
import { CmsImage } from './Components/CmsImages';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsHeadline } from './Components/CmsHeadline';
import { CmsRichtext } from './Components/CmsRichText';
import { CmsButton } from './Components/CmsButton';
import { CmsVideo } from './Components/CmsVideo';
import { CmsCfVideo } from './Components/CmsCfVideo';

const ElementInfoboxOneColumn = ({ item, row }) => {
  const fontClasses = item?.font_color === false ? [styles.FontColorSecondary] : [];
  const optionClasses =
    item?.text_align === false
      ? [styles.TextAlign, styles.Column, styles.ColumnFlexOff, styles.infoBoxStyle]
      : [styles.Column, styles.ColumnFlexOff, styles.infoBoxStyle];
  const spaceClasses = item?.bottom_space === true ? [styles.SpaceRemoval] : [];
  const wrapperBgColor = item?.background_color ? item.background_color : '#ffffff';
  const columnImage = item?.image && item?.image?.length ? item.image[0] : null;
  const columnTarget = columnImage ? item.image[0].sys.id : null;
  const columnImageLarge = columnImage ? item.image[0].fields.file.url : null;
  const columnImageSmall = columnImage && item.image[1] ? item.image[1].fields.file.url : null;
  const columnImageAlt = columnImage ? item?.image[0].fields : null;
  const columnImageCopyright = item?.image_copyright || null;
  const columnVideo = item?.video ? item.video : null;
  const columnHeadline = item?.headline;
  const columnHeadlineType = item?.headline_type;
  const columnText = item?.text;
  const columnSmallPrint = item?.small_print;
  const columnTextFormat = item?.text_align;
  const columnCfVideo = item?.cfVideo?.fields?.file?.url || null;
  const columnCfVideoThumb = item?.cfVideoThumb?.fields?.file?.url || null;
  const columnCfVideoTitle = item?.cfVideo?.fields?.title || null;
  const columnCta = item?.cta;
  const columnCtaLink = item?.cta_link ? item.cta_link : null;
  const columnBgColor = item?.boxBgColor ? item.boxBgColor : '#ffffff';

  return (
    <section
      style={{ backgroundColor: `${wrapperBgColor}` }}
      className={spaceClasses.join(' ')}
      data-cms-block="infobox_1_column"
    >
      <Container className={fontClasses.join(' ')}>
        <CmsRowHeadline item={item} bgColor={wrapperBgColor} />
        <div className={styles.oneColumnRow}>
          <div className={optionClasses.join(' ')} style={{ backgroundColor: `${columnBgColor}` }}>
            {columnImage ? (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className={columnCtaLink ? 'Interactive' : null}
                onClick={columnCtaLink ? () => document.getElementById(`${columnTarget}`).click() : null}
              >
                <CmsImage
                  largeImage={columnImageLarge}
                  smallImage={columnImageSmall}
                  imageAlt={columnImageAlt}
                  imgCopyright={columnImageCopyright}
                  source="ElementInfoboxOneColumn.js"
                  useHeight={false}
                  row={row}
                />
              </div>
            ) : null}
            {!columnImage && columnVideo ? (
              <CmsVideo videoId={columnVideo} videoCopyright={columnImageCopyright} />
            ) : null}
            {columnCfVideo ? (
              <CmsCfVideo videoUrl={columnCfVideo} videoThumb={columnCfVideoThumb} videoTitle={columnCfVideoTitle} />
            ) : null}
            {columnHeadline ? <CmsHeadline headline={columnHeadline} headlineTag={columnHeadlineType} /> : null}
            {columnText ? (
              <CmsRichtext text={columnText} smallPrint={columnSmallPrint} textFormat={columnTextFormat} />
            ) : null}
            {columnCta && columnCtaLink ? (
              <CmsButton
                types={!columnHeadline && !columnText && !columnSmallPrint && columnImage ? 'invisible' : null}
                id={columnTarget}
                absoluteLink={columnCtaLink}
                buttonText={columnCta}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ElementInfoboxOneColumn;
