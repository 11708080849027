import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { I18n, T } from '@deity/falcon-i18n';
import { uniqWith, isEqual } from 'lodash';
import Container from '../../../layout/Container/Container';
import { Button } from '../../../ui/Button/Button';
import { FormField } from '../../../ui/Forms/FormField';
import { STREET_REGEX, streetValidator } from '../../../helpers/Validators/Validators';
import { Checkbox } from '../../../ui/Checkbox/Checkbox';
import { CountryListQueryExt } from '../../../overrides/CountryPicker/CountryListQueryExt';
import { CountryPicker } from '../../../overrides/CountryPicker/CountryPicker';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { ZipListQuery } from '../../../helpers/ZipValidation/ZipListQuery';
import { useGlobalStateValue } from '../../../helpers/GlobalState/GlobalState';
import loadable from '../../../../../../components/loadable';
import { CmsQAItem } from './Components/CmsQAItem';
import styles from './CmsModules.module.scss';
import { CmsHeadline } from './Components/CmsHeadline';
import { SetQuizAnswerMutation } from './Mutations/ElementQAMutation';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../ui/Modal/Modal'));

const ElementQA = withStoreConfiguration(({ storeConfiguration, item }) => {
  const [, dispatch] = useGlobalStateValue();
  const [datatat, setDatatat] = useState([]);
  const [errors, setErrors] = useState(true);
  const [formErrors, setFormErrors] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [showTermsOverlay, setShowTermsOverlay] = useState(false);
  const [showQuizForm, setShowQuizForm] = useState(true);
  const [showQuizThankYou, setShowQuizThankYou] = useState(false);
  const defaultCountry = storeConfiguration.general.country.default;
  const agbLabel = (
    <I18n>
      {t => (
        <span className="normal">
          {t('qaForm.termsLabel')}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span onClick={() => setShowTermsOverlay(true)}>{t('qaForm.terms')}</span>.
        </span>
      )}
    </I18n>
  );

  const qaItemObject = {
    question: 'Frage',
    answer: 'Antwort',
    correctAnswer: 'Richtige Antwort'
  };

  // workaround for subscription poll, after poll should be removed
  const pollSource = typeof window !== 'undefined' ? window.location.pathname : null;

  const pushCheckbox = values => {
    const questions = Object.keys(values)
      .filter(key => {
        return key.indexOf('question') === 0;
      })
      .reduce((generatedObj, key) => {
        generatedObj[key] = values[key];
        return generatedObj;
      }, {});
    const checkboxQuestions = Object.keys(questions)
      .filter(key => {
        return key.includes('answer');
      })
      .reduce((generatedObj, key) => {
        generatedObj[key] = values[key];
        return generatedObj;
      }, {});

    const compiledAnswers = [];
    const arrayCheckboxQuestions = Object.entries(checkboxQuestions).map((key, index) => {
      const generatedObj = {};
      const questionNr = key[0].split('_')[1];
      const answerNr = key[0].split('_')[3];
      const correctAnswerNr = item.qa_container[questionNr].fields.correct_answers;
      const correctAnswers = [];
      if (correctAnswerNr) {
        correctAnswerNr.map(correctAnswer =>
          correctAnswers.push(item.qa_container[questionNr].fields.answers[correctAnswer])
        );
      }
      generatedObj[index] = Object.create(qaItemObject);
      generatedObj[index].question = item.qa_container[questionNr].fields.question;
      generatedObj[index].answer = item.qa_container[questionNr].fields.answers[answerNr];
      if (correctAnswerNr && correctAnswers) {
        generatedObj[index].correctAnswer = correctAnswers;
      }
      return generatedObj[index];
    });

    arrayCheckboxQuestions.forEach(target => {
      const existing = compiledAnswers.filter(source => {
        return source.question === target.question;
      });
      if (existing.length) {
        const existingIndex = compiledAnswers.indexOf(existing[0]);
        compiledAnswers[existingIndex].answer = compiledAnswers[existingIndex].answer.concat(target.answer);
      } else {
        if (typeof target.answer === 'string') target.answer = [target.answer];
        compiledAnswers.push(target);
      }
    });
    setDatatat([...datatat, ...compiledAnswers]);
  };

  const pushRadio = values => {
    const questions = Object.keys(values)
      .filter(key => {
        return key.indexOf('question') === 0;
      })
      .reduce((generatedObj, key) => {
        generatedObj[key] = values[key];
        return generatedObj;
      }, {});
    const radioQuestions = Object.keys(questions)
      .filter(key => {
        return !key.includes('answer');
      })
      .filter(key => {
        return !key.includes('textfield');
      })
      .reduce((generatedObj, key) => {
        generatedObj[key] = values[key];
        return generatedObj;
      }, {});

    const arrayRadioQuestions = Object.entries(radioQuestions).map((key, index) => {
      const generatedObj = {};
      const questionNr = key[0].split('_')[1];
      const correctAnswerNr =
        item.qa_container[questionNr].fields.correct_answers && item.qa_container[questionNr].fields.correct_answers[0];
      generatedObj[index] = Object.create(qaItemObject);
      generatedObj[index].question = item.qa_container[questionNr].fields.question;
      generatedObj[index].answer = key[1];
      if (correctAnswerNr) {
        generatedObj[index].correctAnswer = item.qa_container[questionNr].fields.answers[correctAnswerNr - 1];
      }
      return generatedObj[index];
    });
    setDatatat([...datatat, ...arrayRadioQuestions]);
  };

  const pushTextarea = values => {
    const questions = Object.keys(values)
      .filter(key => {
        return key.indexOf('question') === 0;
      })
      .reduce((generatedObj, key) => {
        generatedObj[key] = values[key];
        return generatedObj;
      }, {});

    const textareaQuestions = Object.keys(questions)
      .filter(key => {
        return key.includes('textfield');
      })
      .reduce((generatedObj, key) => {
        generatedObj[key] = values[key];
        return generatedObj;
      }, {});

    const arrayTextareaQuestions = Object.entries(textareaQuestions).map((key, index) => {
      const generatedObj = {};
      const questionNr = key[0].split('_')[1];
      generatedObj[index] = Object.create(qaItemObject);
      generatedObj[index].question = item.qa_container[questionNr].fields.question;
      generatedObj[index].answer = key[1];
      return generatedObj[index];
    });
    setDatatat([...datatat, ...arrayTextareaQuestions]);
  };

  const pushData = (type, values) => {
    if (type === 'Checkbox') {
      pushCheckbox(values);
    }
    if (type === 'Radio') {
      pushRadio(values);
    }
    if (type === 'Textfield') {
      pushTextarea(values);
    }
  };

  const quizData = uniqWith(datatat, isEqual);

  const showNotification = (type, content) => {
    window.scrollBy(0, -10000);
    dispatch({
      type: 'ADD_TO_STATE',
      section: 'messages',
      data: {
        content,
        type,
        animate: false
      }
    });
  };

  const validate = (values, zipList, t) => {
    const validationError = {};
    const trimName = values.firstname.trim();
    const trimLastName = values.lastname.trim();
    const trimStreet = values.street.trim();
    const trimCity = values.city.trim();
    const requiredField = (
      <div className={styles.qaFormRequired}>
        <T id="qaForm.termsRequired" />
      </div>
    );

    if (item.contact_formular === true) {
      if (trimName === '') {
        validationError.firstname = <T id="formError.requiredFirstname" />;
      }

      if (trimLastName === '') {
        validationError.lastname = <T id="formError.requiredLastname" />;
      }

      if (trimCity === '') {
        validationError.city = <T id="formError.requiredCity" />;
      }

      if (!values.terms) {
        validationError.terms = requiredField;
      }

      if (!STREET_REGEX.test(trimStreet)) {
        validationError.street = <T id="formError.missingHouseNr" />;
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        validationError.mail = <React.Fragment />;
      }

      let ZIP_REGEX = null;
      // find country and related zip pattern and examples
      const countryZip = zipList.find(listItem => listItem.country === values.countryId);
      // check for pattern length to identify the regex pattern which should be applied
      if (countryZip?.pattern?.length === 1) {
        ZIP_REGEX = new RegExp(countryZip?.pattern?.[0]?.pattern);
        const { example } = countryZip?.pattern?.[0];
        // regex check and create error message with example
        if (!ZIP_REGEX.test(values.postcode.trim())) {
          validationError.postcode = `${t('formError.zipError')}: ${example} `;
        }
      } else if (countryZip?.pattern?.length > 1) {
        // multiple pattern check for invalid matches
        const valid = countryZip?.pattern?.map(subPattern => {
          let invalid = 0;
          ZIP_REGEX = new RegExp(subPattern[1].pattern);
          if (ZIP_REGEX.test(values.postcode.trim())) {
            invalid++;
          }
          return invalid;
        });
        // create error message with multiple examples for invalid match
        if (!valid.includes(1)) {
          const examples = countryZip?.pattern?.map(subPattern => {
            return ` ${subPattern[1].example}`;
          });
          validationError.postcode = `${t('formError.zipError')}: ${examples}.`;
        }
      }
    }

    if (Object.keys(validationError).length === 0 && errors) {
      setFormErrors(false);
    } else {
      setFormErrors(true);
    }

    return validationError;
  };
  return (
    <I18n>
      {t => (
        <ZipListQuery>
          {({ data: { zipList } }) => (
            <section data-cms-block="element_qa">
              {showQuizForm ? (
                <Container>
                  <SetQuizAnswerMutation>
                    {setQuizAnswer => (
                      <Formik
                        initialValues={{
                          quizId: item.quiz_id,
                          // workaround for subscription poll, after poll should be removed
                          firstname: !item.contact_formular ? pollSource : '',
                          lastname: '',
                          street: '',
                          postcode: '',
                          city: '',
                          countryId: defaultCountry,
                          email: '',
                          terms: !item.contact_formular,
                          newsletter_subscriber: false
                        }}
                        onSubmit={values => {
                          try {
                            values.answers = quizData;
                            values.participant = {
                              firstname: values.firstname,
                              lastname: values.lastname,
                              street: values.street,
                              postcode: values.postcode,
                              city: values.city,
                              countryId: values.countryId,
                              email: values.email
                            };
                            setQuizAnswer({ variables: { ...values } }).then(result => {
                              if (result.data.setQuizAnswer.success !== true) {
                                showNotification('error', result.data.setQuizAnswer.message);
                              } else {
                                showNotification('success', result.data.setQuizAnswer.message);
                                setShowQuizForm(false);
                                setShowQuizThankYou(!item.contact_formular);
                              }
                            });
                          } catch (e) {
                            if (e) {
                              showNotification('error', e);
                            }
                          }
                        }}
                        validate={values => validate(values, zipList.list, t)}
                      >
                        {({ values }) => {
                          return (
                            <Form id="QA" className={styles.qaContainer}>
                              {item.qa_container.map((qaItem, index) => (
                                <CmsQAItem
                                  key={`qa-${index}`} //eslint-disable-line
                                  questionId={`question_${index}`}
                                  item={qaItem}
                                  values={values}
                                  errors={errors}
                                  setErrors={setErrors}
                                  onePager={item.type}
                                  page={index}
                                  pageCount={pageCount}
                                  setPageCount={setPageCount}
                                  lastPage={
                                    item.contact_formular === true
                                      ? item.qa_container.length
                                      : item.qa_container.length - 1
                                  }
                                  pushData={pushData}
                                />
                              ))}
                              {item.contact_formular === true && (pageCount === item.qa_container.length || item.type) && (
                                <React.Fragment>
                                  <CmsHeadline headline={t('qaForm.title')} headlineTag="h2" />
                                  <FormField
                                    name="firstname"
                                    label={t('signUp.firstname')}
                                    placeholder={t('signUp.firstname')}
                                    required
                                    showErrorMessage
                                  />
                                  <FormField
                                    name="lastname"
                                    label={t('signUp.lastname')}
                                    placeholder={t('signUp.lastname')}
                                    required
                                    showErrorMessage
                                  />
                                  <FormField
                                    name="street"
                                    label={t('signUp.street')}
                                    placeholder={t('signUp.street')}
                                    required
                                    showErrorMessage
                                    validate={[streetValidator]}
                                  />
                                  <FormField
                                    name="postcode"
                                    label={t('signUp.postcode')}
                                    placeholder={t('signUp.postcode')}
                                    required
                                    showErrorMessage
                                  />
                                  <FormField
                                    name="city"
                                    label={t('signUp.city')}
                                    placeholder={t('signUp.city')}
                                    required
                                    showErrorMessage
                                  />
                                  <CountryListQueryExt passLoading>
                                    {({ data: { countryList } }) => {
                                      return (
                                        <FormField name="countryId">
                                          {({ form, field }) =>
                                            countryList ? (
                                              <CountryPicker
                                                {...field}
                                                options={countryList.items}
                                                onChange={x => {
                                                  form.setFieldValue(field.name, x);
                                                }}
                                                selectedCountry={defaultCountry}
                                              />
                                            ) : null
                                          }
                                        </FormField>
                                      );
                                    }}
                                  </CountryListQueryExt>
                                  <FormField
                                    type="email"
                                    name="email"
                                    label={t('signUp.email')}
                                    placeholder={t('signUp.email')}
                                    required
                                    autoComplete="email"
                                    showErrorMessage
                                  />
                                  <div className={styles.qaFormTerms}>
                                    <ErrorMessage name="terms" />
                                    <Field
                                      required
                                      name="terms"
                                      render={({ field }) => {
                                        return (
                                          <Checkbox
                                            name="terms"
                                            id="terms"
                                            buttonOnTop
                                            type="checkbox"
                                            checked={field.value.terms}
                                            label={agbLabel}
                                            {...field}
                                          />
                                        );
                                      }}
                                    />
                                  </div>
                                  <Field
                                    name="newsletter_subscriber"
                                    render={({ field }) => {
                                      return (
                                        <Checkbox
                                          name="newsletter_subscriber"
                                          id="newsletter_subscriber"
                                          type="checkbox"
                                          buttonOnTop
                                          checked={field.value.newsletter_subscriber}
                                          label={<span className="normal">{t('qaForm.newsletterLabel')}</span>}
                                          {...field}
                                        />
                                      );
                                    }}
                                  />
                                </React.Fragment>
                              )}
                              {(!item.type && pageCount === item.qa_container.length) || item.type ? (
                                <Button type="submit" size="small" disabled={formErrors === true && errors === true}>
                                  <T id="qaForm.send" />
                                </Button>
                              ) : null}
                            </Form>
                          );
                        }}
                      </Formik>
                    )}
                  </SetQuizAnswerMutation>
                </Container>
              ) : null}
              {showQuizThankYou ? (
                <Container>
                  <div className={styles.qaContainerThankYou}>
                    <CmsHeadline headline={t('subscription.thankYou')} headlineTag="h2" />
                  </div>
                </Container>
              ) : null}
              {showTermsOverlay ? (
                <Modal
                  pageContent={t('cms.contest.link')}
                  variant="tertiary"
                  scrollableContent
                  visible={showTermsOverlay}
                  close={() => {
                    setShowTermsOverlay(false);
                  }}
                />
              ) : null}
            </section>
          )}
        </ZipListQuery>
      )}
    </I18n>
  );
});

export default ElementQA;
