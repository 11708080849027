import React from 'react';
import { Field } from 'formik';
import window from 'global';
import { T } from '@deity/falcon-i18n';
import { Radio } from '../../../../ui/Radio/Radio';
import { Checkbox } from '../../../../ui/Checkbox/Checkbox';
import { FormField } from '../../../../ui/Forms/FormField';
import { Button } from '../../../../ui/Button/Button';
import styles from './CmsQAItem.module.scss';
import { CmsHeadline } from './CmsHeadline';

export const CmsQAItem = ({
  questionId,
  item,
  values,
  errors,
  setErrors,
  onePager,
  page,
  pageCount,
  setPageCount,
  lastPage,
  pushData
}) => {
  const radioValidator = ({ value }) => {
    if (value) {
      setErrors(false);
    }
  };

  const textValidator = ({ value }) => {
    if (value !== '' || value) {
      setErrors(false);
    }
  };

  const checkboxValidator = () => {
    const x = item.fields.answers;
    let i;
    let counter = 0;
    if (window.document) {
      for (i = 0; i < x.length; i++) {
        if (window.document.getElementById(x[i]) !== null && window.document.getElementById(x[i]).checked === true) {
          counter++;
        }
      }
    }
    if (counter >= 1) {
      setErrors(false);
    } else setErrors(true);
  };

  if (page === pageCount || onePager) {
    const required = item.fields.required || typeof item.fields.required === 'undefined';
    return (
      <React.Fragment>
        {item.fields.type === 'Textfield' && (
          <div className={styles.CmsQAText}>
            <CmsHeadline headline={item.fields.question} headlineTag="h2" />
            <FormField
              validate={[textValidator]}
              name={`${questionId}_textfield`}
              textarea
              required={required}
              noSign
              autoComplete="off"
              label={item.fields.question}
              placeholder={item.fields.question}
            />
          </div>
        )}
        {item.fields.type === 'Radio' && (
          <div className={styles.CmsQARadio}>
            <CmsHeadline headline={item.fields.question} headlineTag="h2" />
            <FormField name={questionId} validate={[radioValidator]}>
              {({ form, field }) => {
                return item.fields.answers.map((answers, index) => (
                  <Radio
                    key={`answers_${index}`} //eslint-disable-line
                    name={`${questionId}_answer_${index}`}
                    value={answers}
                    label={answers}
                    checked={field.value === answers}
                    onChange={x => form.setFieldValue(field.name, x.target.value)}
                  />
                ));
              }}
            </FormField>
          </div>
        )}
        {item.fields.type === 'Checkbox' && (
          <div className={styles.CmsQACheckbox}>
            <CmsHeadline headline={item.fields.question} headlineTag="h2" />
            {item.fields.answers.map((answers, index) => (
              <Field
                onChange={checkboxValidator()}
                name={`${questionId}_answer_${index}`}
                render={({ field }) => {
                  return (
                    <Checkbox
                      key={`answers_${index}`} //eslint-disable-line
                      name={`${answers}`}
                      id={answers}
                      label={answers}
                      variant="secondary"
                      {...field}
                    />
                  );
                }}
              />
            ))}
          </div>
        )}
        {!onePager && pageCount !== lastPage ? (
          <Button
            size="small"
            disabled={required && errors === true}
            onClick={() => [setPageCount(pageCount + 1), setErrors(true), pushData(item.fields.type, values)]}
          >
            <T id="qaForm.next" />
          </Button>
        ) : null}
        {!onePager && pageCount === lastPage ? (
          <Button
            size="small"
            type="submit"
            disabled={required && errors === true}
            onClick={() => pushData(item.fields.type, values)}
          >
            <T id="qaForm.send" />
          </Button>
        ) : null}
      </React.Fragment>
    );
  }
  return null;
};
