import React, { useState } from 'react';
import { InnerHTML } from '@deity/falcon-ui-kit';
import { useKeenSlider } from 'keen-slider/react';
import Container from '../../../layout/Container/Container';
import styles from './TagNavigation.module.scss';

const loopRemove = (getEl, sty) => {
  const target = () => document.getElementsByClassName(getEl);
  let i;
  for (i = 0; i < target(getEl).length; i++) {
    target(getEl)[i].classList.remove(sty);
  }
};

const openSubField = (SubFieldWrapper, TagNav) => {
  if (!document.getElementById(SubFieldWrapper).classList.contains(styles.Active)) {
    loopRemove(styles.SubFieldWrapper, styles.Active);
    document.getElementById(SubFieldWrapper).classList.add(styles.Active);
  }
  if (!document.getElementById(TagNav).classList.contains(styles.Active)) {
    loopRemove(styles.TagNavigation, styles.Active);
    document.getElementById(TagNav).classList.add(styles.Active);
  }
};

const closeSubField = SubFieldWrapper => {
  if (document.getElementById(SubFieldWrapper).classList.contains(styles.Active)) {
    document.getElementById(SubFieldWrapper).classList.remove(styles.Active);
  }
};

const closeNav = () => {
  loopRemove(styles.SubFieldWrapper, styles.Active);
  loopRemove(styles.TagNavigation, styles.Active);
};

const TagNavigation = ({ item }) => {
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    mode: 'snap',
    rtl: false,
    slides: { perView: 'auto', spacing: 10 },
    created() {
      setLoaded(true);
    }
  });

  return (
    <Container data-cms-block="tag_navigation">
      <div className={styles.TagNavigationCloseCapture} onMouseOver={closeNav} onFocus={closeNav} />
      <div className={styles.TagNavigationContainer}>
        <div className={styles.TagNavigationWrapper}>
          {loaded && instanceRef.current && (
            <button
              type="button"
              aria-label="prev"
              className="icon-chevron-left"
              onMouseOver={closeNav}
              onFocus={closeNav}
              onTouchStart={closeNav}
              onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
            />
          )}
          <div ref={sliderRef} className="keen-slider">
            {item?.navigation_items?.map((navItem, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`tag-${index}`} className={[styles.TagNavigationSlide, 'keen-slider__slide'].join(' ')}>
                {navItem.fields.link && !navItem.fields.sub_field ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <a
                    href={navItem.fields.link}
                    className={styles.TagNavigation}
                    onMouseEnter={closeNav}
                    onFocus={closeNav}
                  >
                    {navItem.fields.name}
                  </a>
                ) : (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <span
                            key={`tag-${index}`} /* eslint-disable-line */
                    id={`tag-${index}`}
                    className={styles.TagNavigation}
                    onTouchStart={() => openSubField(`field-${index}`, `tag-${index}`)}
                    onMouseEnter={() => openSubField(`field-${index}`, `tag-${index}`)}
                    onFocus={() => openSubField(`field-${index}`, `tag-${index}`)}
                  >
                    {navItem.fields.name}
                  </span>
                )}
              </div>
            ))}
          </div>
          {loaded && instanceRef.current && (
            <button
              type="button"
              aria-label="next"
              className="icon-chevron-right"
              onMouseOver={closeNav}
              onFocus={closeNav}
              onTouchStart={closeNav}
              onClick={e => e.stopPropagation() || instanceRef.current?.next()}
            />
          )}
        </div>

        {item?.navigation_items?.map((navItem, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`field-${index}`} onMouseLeave={() => closeSubField(`field-${index}`)}>
            {navItem.fields.sub_field && (
              // eslint-disable-next-line react/no-array-index-key
              <div id={`field-${index}`} className={styles.SubFieldWrapper}>
                <span>
                  <a href={navItem.fields.link}>{navItem.fields.name}</a>
                </span>
                <InnerHTML className={styles.SubField} html={navItem.fields.sub_field} />
                <button
                  className={[styles.SubFieldBtn, 'icon-chevron-up', 'ShowMobile', 'ShowTablet'].join(' ')}
                  type="button"
                  onClick={closeNav}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default TagNavigation;
