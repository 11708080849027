import React, { useState } from 'react';
import { I18n } from '@deity/falcon-i18n';
import { Box } from '@deity/falcon-ui';
import { ErrorSummary, Form, InnerHTML, Loader } from '@deity/falcon-ui-kit';
import { AddToCartFormProvider } from '@deity/falcon-front-kit';
import parse from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import Container from '../../../layout/Container/Container';
import styles from '../../../pages/Product/Product.module.scss';
import { ReviewsSummary } from '../../Reviews/ReviewsSummary/ReviewsSummary';
import {
  AddedToCartPopup,
  BasePrice,
  Price,
  Stock,
  CustomOptions,
  Gallery,
  ActiveProduct,
  Variants,
  VariantContext,
  PriceDate
} from '../../Product';
import { ChangeItemNumberInput } from '../../../pages/Cart/CartInputs/CartInputs';
import { Button } from '../../../ui/Button/Button';
import { AddToWishlist } from '../../Wishlist/AddToWishlist/AddToWishlist';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { AddedToWishlistPopup } from '../../Wishlist/AddedToWishlistPopup/AddedToWishlistPopup';
import { ProductQueryExt } from '../../../overrides/Product/ProductQueryExt';
import { CartTracking } from '../../Tracking/GoogleTagManager';
import { getFreeOfShipping } from '../../../helpers/FreeShipping/FreeShipping';
import loadable from '../../../../../../components/loadable';
import { LastOrderUpdateTracking } from '../../LastOrderUpdateTracking/LastOrderUpdateTracking';
import { CmsHeadline } from './Components/CmsHeadline';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../ui/Modal/Modal'));

const CatalogProduct = withStoreConfiguration(({ storeConfiguration, item, id, path }) => {
  const productRef = item.sku || null;
  const [showAddedToCart, setShowAddedToCart] = useState(false);
  const [showAddedToWishlist, setShowAddedToWishlist] = useState(false);
  const [showDeliveryOverlay, setShowDeliveryOverlay] = useState(false);
  const [showIngredientsOverlay, setShowIngredientsOverlay] = useState(false);
  const [priceModificator, setPriceModificator] = useState(0);
  const [isCartUpdated, setIsCartUpdated] = useState(false);
  const [showAgbOverlay, setShowAgbOverlay] = useState(false);
  const productPath = item.sku && path ? `/${path}.html` : null;
  const maxQtyAllowedInCart = storeConfiguration?.cataloginventory?.item_options?.max_sale_qty;
  const deliveryException = !!parseInt(storeConfiguration?.asam_shipping?.delivery_exception?.active, 10);
  const deliveryMessage = storeConfiguration?.asam_shipping?.delivery_exception?.message || '';
  const deliveryExceptionSkus = storeConfiguration?.asam_shipping?.delivery_exception?.products_skus?.split(',') || [];

  if (productRef && productPath) {
    return (
      <section data-cms-block="catalog_product">
        <ProductQueryExt variables={{ id, path: productPath }}>
          {({ data: { product }, loading }) => {
            if (loading) {
              return <Loader />;
            }
            return (
              <ActiveProduct product={product}>
                {({ activeProduct, selectedOptions, selectActiveProduct }) => {
                  const cosBrandHtml = product.brand ? (
                    <div className={styles.ProductBrand}>{product.brand}</div>
                  ) : null;
                  const { contentData, sku } = activeProduct;
                  const productPriceOptions =
                    product.customOptions &&
                    product.customOptions.filter(option => {
                      return (
                        Object.prototype.hasOwnProperty.call(option, 'values') &&
                        option.values.filter(el => {
                          return Object.prototype.hasOwnProperty.call(el, 'price') && el.price > 0;
                        }).length > 0
                      );
                    }).length > 0;
                  const freeOfShipping = getFreeOfShipping(storeConfiguration, priceModificator, product);
                  const ingredients = (
                    <I18n>
                      {t => (
                        <React.Fragment>
                          <CmsHeadline headlineTag="h3" headline={t('product.ingredients')} />
                          <InnerHTML html={contentData?.ingredients} />
                        </React.Fragment>
                      )}
                    </I18n>
                  );
                  const deliveryDelayed = deliveryExceptionSkus?.includes(sku);

                  return (
                    <I18n>
                      {t => (
                        <Container>
                          <div className={styles.Product}>
                            <div className={styles.ProductTitleWithSummary}>
                              <p className={styles.ProductName}>
                                {/* eslint-disable-next-line */}
                                {cosBrandHtml}{' '}{product.name}
                              </p>
                              <ReviewsSummary summary={product.reviewsSummary} />
                              <div className="Divider DividerHorizontal" />
                            </div>
                            <div className={styles.ProductGallery}>
                              <div className={styles.ProductGalleryWrapper}>
                                <Gallery
                                  items={activeProduct.gallery}
                                  alt={activeProduct.name}
                                  labels={activeProduct.labels}
                                />
                              </div>
                            </div>
                            <div className={styles.ProductInfo}>
                              {contentData?.description ? (
                                <div className={styles.ProductShortDescription}>
                                  {parse(contentData?.description, {
                                    replace: node => {
                                      if ('name' in node && node.name === 'li') {
                                        return <li className="before-icon-check">{domToReact(node.children)}</li>;
                                      }
                                    }
                                  })}
                                </div>
                              ) : null}
                              <div className="Divider DividerHorizontal" />
                              {activeProduct !== product ? (
                                <>
                                  <Variants
                                    options={product.options}
                                    context={VariantContext.pdp}
                                    activeProduct={activeProduct}
                                    selectedOptions={selectedOptions}
                                    selectVariant={(option, value) => selectActiveProduct(option, value)}
                                  />
                                </>
                              ) : null}
                              <div className="Divider DividerHorizontal" />
                              <AddToCartFormProvider
                                quantity={1}
                                product={activeProduct}
                                onSuccess={() => {
                                  setShowAddedToCart(true);
                                  CartTracking({
                                    storeConfiguration,
                                    product: activeProduct,
                                    action: 'add',
                                    origin: 'product_detail_page'
                                  });
                                  setIsCartUpdated(true);
                                }}
                              >
                                {({ isSubmitting, status = {}, setFieldValue, values }) => (
                                  <div className={styles.ProductPriceInfoWrapper}>
                                    {activeProduct.customOptions.length ? (
                                      <CustomOptions
                                        options={activeProduct.customOptions}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        setPriceModificator={setPriceModificator}
                                      />
                                    ) : null}
                                    <div className={styles.ProductPriceInfo}>
                                      <div className={styles.ProductPrice}>
                                        <Price
                                          value={activeProduct.price.regular + priceModificator}
                                          specialPrice={
                                            activeProduct.price.special
                                              ? activeProduct.price.special + priceModificator
                                              : null
                                          }
                                        />
                                      </div>
                                      <div className={styles.ProductContent}>
                                        <div className={styles.ProductFreeShipping}>
                                          {freeOfShipping ? (
                                            <div className={styles.ProductFreeShippingBox}>
                                              <div className={styles.ProductFreeTooltip}>
                                                {t('product.freeShipping')}
                                              </div>
                                              <div className={styles.ProductInclTax}>
                                                ({t('product.inclTax')}
                                                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                                <span onClick={() => setShowDeliveryOverlay(true)}>
                                                  {t('product.deliveryshipping')}
                                                </span>
                                                {t('product.inclTaxOne')})
                                              </div>
                                            </div>
                                          ) : (
                                            <div className={styles.ProductInclTax}>
                                              ({t('product.exclShipping')}
                                              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                              <span onClick={() => setShowDeliveryOverlay(true)}>
                                                {t('product.deliveryshipping')}
                                              </span>
                                              {t('product.exclShippingOne')})
                                            </div>
                                          )}
                                        </div>
                                        <PriceDate
                                          dateFrom={product.price.specialPriceFrom}
                                          dateTo={product.price.specialPriceTo}
                                        />
                                        <div>Art-Nr.: {sku}</div>
                                        <div>
                                          {product.volume ? `${t('product.content')}: ${product.volume}` : null}
                                          {activeProduct.baseprice && activeProduct.baseprice !== 'false' ? (
                                            <BasePrice info={activeProduct.baseprice} />
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Divider DividerHorizontal" />
                                    <div className={styles.ProductAddToCart}>
                                      {activeProduct.stock.isInStock &&
                                      (activeProduct.price.regular > 0 || productPriceOptions) ? (
                                        <Form id="add-to-cart" i18nId="product">
                                          <div className={styles.ProductQuantity}>
                                            <ChangeItemNumberInput
                                              max={maxQtyAllowedInCart}
                                              id={`product${product.id}`}
                                              defaultValue={1}
                                              onChange={ev => setFieldValue('qty', ev.target.value)}
                                            />
                                          </div>
                                          <Button type="submit" size="large" loading={isSubmitting}>
                                            {t('product.addToCart')}
                                          </Button>
                                        </Form>
                                      ) : (
                                        <Button types="ghost" size="large" disabled>
                                          {t('product.notAvailable')}
                                        </Button>
                                      )}
                                      <Box>{status.error && <ErrorSummary errors={status.error} />}</Box>
                                    </div>
                                  </div>
                                )}
                              </AddToCartFormProvider>
                              {isCartUpdated && <LastOrderUpdateTracking product={activeProduct} />}
                              {activeProduct.customOptions.length === 0 && activeProduct.price.regular > 0 && (
                                <div className={styles.ProductAddToWishlist}>
                                  <AddToWishlist
                                    productId={activeProduct.id}
                                    variant="secondary"
                                    onSuccess={() => setShowAddedToWishlist(true)}
                                    origin="cms element"
                                    product={activeProduct}
                                  />
                                </div>
                              )}
                              <div className={styles.ProductInfoWrapper}>
                                {deliveryException && deliveryDelayed ? (
                                  <>
                                    <div className={styles.DeliveryDelayed}>
                                      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                      <span onClick={() => setShowAgbOverlay(true)} className="Interactive Underline">
                                        {deliveryMessage}
                                      </span>
                                    </div>
                                    {showAgbOverlay ? (
                                      <Modal
                                        pageContent={t('cms.estimatedDelivery.link')}
                                        variant="tertiary"
                                        scrollableContent
                                        visible={showAgbOverlay}
                                        close={() => {
                                          setShowAgbOverlay(false);
                                        }}
                                      />
                                    ) : null}
                                  </>
                                ) : (
                                  <Stock stock={activeProduct.stock} />
                                )}
                                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                <span
                                  className={styles.ProductIngredients}
                                  onClick={() => setShowIngredientsOverlay(true)}
                                >
                                  {t('product.ingredients')}
                                </span>
                              </div>
                            </div>

                            <AddedToCartPopup on={showAddedToCart} toggle={() => setShowAddedToCart(false)} />
                            {showAddedToWishlist ? (
                              <AddedToWishlistPopup
                                on={showAddedToWishlist}
                                toggle={() => setShowAddedToWishlist(false)}
                              />
                            ) : null}
                            {showDeliveryOverlay ? (
                              <Modal
                                pageContent={t('cms.deliveryshipping.link')}
                                variant="tertiary"
                                scrollableContent
                                visible={showDeliveryOverlay}
                                close={() => {
                                  setShowDeliveryOverlay(false);
                                }}
                              />
                            ) : null}
                            {showIngredientsOverlay ? (
                              <Modal
                                content={ingredients}
                                variant="tertiary"
                                scrollableContent
                                visible={showIngredientsOverlay}
                                close={() => {
                                  setShowIngredientsOverlay(false);
                                }}
                              />
                            ) : null}
                          </div>
                        </Container>
                      )}
                    </I18n>
                  );
                }}
              </ActiveProduct>
            );
          }}
        </ProductQueryExt>
      </section>
    );
  }
});

export default CatalogProduct;
