import React, { useEffect } from 'react';

export const CmsCareer = () => {
  const setCareerFrame = () => {
    const existingFrame = document.getElementById('career-frame');
    const careerFrameHolder = document.getElementById('career-frame-holder');

    if (!existingFrame) {
      const careerFrame = document.createElement('iframe');
      careerFrame.src = 'https://asambeauty.softgarden.io/widgets/jobs';
      careerFrame.id = 'career-frame';
      careerFrame.height = '650';
      careerFrame.width = '100%';
      careerFrame.frameBorder = '0';
      careerFrameHolder.appendChild(careerFrame);
    }

    return undefined;
  };

  useEffect(() => {
    setCareerFrame();
  }, []);

  return <div id="career-frame-holder" />;
};
