import React from 'react';
import styles from './CmsVideo.module.scss';

export const FacebookLive = ({ widgetSource }) => {
  if (widgetSource) {
    const iframeCode = `<iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fasambeauty%2Fvideos%2F${widgetSource}%2F&width=1280" data-cookieconsent="ignore" width="1280" height="720" style="border:none;overflow:hidden;max-width:100%;" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>`;

    return <div className={styles.FBVideoHeight} dangerouslySetInnerHTML={{ __html: `${iframeCode}` }} />;
  }

  return null;
};
