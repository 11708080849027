import React from 'react';
import { I18n } from '@deity/falcon-i18n';
import Container from '../../../layout/Container/Container';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import styles from './CmsModules.module.scss';
import { CmsImage } from './Components/CmsImages';
import { CmsVideo } from './Components/CmsVideo';
import { CmsHeadline } from './Components/CmsHeadline';
import { CmsRichtext } from './Components/CmsRichText';
import { CmsButton } from './Components/CmsButton';
import { CmsTeaserBox } from './Components/CmsTeaserBox';
import { CmsWidget } from './Components/CmsWidget';
import { CmsCfVideo } from './Components/CmsCfVideo';

const HomeListColumn = ({ item, row }) => {
  const fontClasses = item.font_color === false ? [styles.FontColorSecondary] : [];
  const optionClasses = item.text_align === false ? [styles.TextAlign, styles.Column] : [styles.Column];
  const spaceClasses = item.bottom_space === true ? [styles.SpaceRemoval] : [];
  const wrapperBgColor = item.background_color ? item.background_color : '#ffffff';

  if (item.column_content_row && item.column_content_row.length >= 4) {
    return (
      <I18n>
        {t => (
          <section
            style={{ backgroundColor: `${wrapperBgColor}` }}
            className={spaceClasses.join(' ')}
            data-cms-block="home_list_column"
          >
            <Container className={fontClasses.join(' ')}>
              <CmsRowHeadline item={item} bgColor={wrapperBgColor} />
              <div className={styles.fourColumnRow}>
                {item.column_content_row.map((itemContent, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={`item-${index}`}>
                    {itemContent.sys.contentType.sys.id === 'home_teaser_box' ? (
                      <CmsTeaserBox
                        title={itemContent.fields.title}
                        subline={itemContent.fields.subline}
                        image={itemContent.fields.image ? itemContent.fields.image : null}
                        relativeLink={
                          itemContent.fields.cta_link_reference ? itemContent.fields.cta_link_reference : null
                        }
                        absoluteLink={itemContent.fields.cta_link ? itemContent.fields.cta_link : null}
                        productLink={
                          itemContent.fields.related_product ? itemContent.fields.related_product.fields : null
                        }
                        labelType={itemContent.fields.label_type}
                        tracking={itemContent.fields.tracking}
                        creativeSlot={index}
                        product={
                          itemContent.fields.related_product
                            ? itemContent.fields.related_product.fields.products[0]
                            : null
                        }
                        t={t}
                        row={row}
                      />
                    ) : (
                      <div className={optionClasses.join(' ')}>
                        {itemContent.fields.widget ? (
                          <CmsWidget
                            itemContent={itemContent}
                            widget={itemContent.fields.widget}
                            widgetSource={itemContent.fields.widget_source}
                          />
                        ) : (
                          <React.Fragment>
                            <div>
                              {itemContent.fields.image ? (
                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                <div
                                  className={
                                    itemContent.fields.cta_link_reference ||
                                    itemContent.fields.related_product ||
                                    itemContent.fields.cta_link
                                      ? 'Interactive'
                                      : null
                                  }
                                  onClick={
                                    itemContent.fields.cta_link_reference ||
                                    itemContent.fields.related_product ||
                                    itemContent.fields.cta_link
                                      ? () => document.getElementById(`${itemContent.fields.image[0].sys.id}`).click()
                                      : null
                                  }
                                >
                                  <CmsImage
                                    largeImage={itemContent.fields.image[0].fields.file.url}
                                    smallImage={
                                      itemContent.fields.image[1] ? itemContent.fields.image[1].fields.file.url : null
                                    }
                                    imageAlt={itemContent.fields.image[0].fields}
                                    imgCopyright={itemContent.fields.image_copyright}
                                    source="HomeListColumn.js"
                                    row={row}
                                  />
                                </div>
                              ) : null}

                              {!itemContent.fields.image && itemContent.fields.video ? (
                                <CmsVideo
                                  videoId={itemContent.fields.video}
                                  videoCopyright={itemContent.fields.image_copyright}
                                />
                              ) : null}

                              {itemContent?.fields?.cfVideo?.fields?.file?.url ? (
                                <CmsCfVideo
                                  itemContent={itemContent}
                                  videoTitle={itemContent?.fields?.cfVideo?.fields?.title}
                                  videoUrl={itemContent?.fields?.cfVideo?.fields?.file?.url}
                                  videoThumb={itemContent?.fields?.cfVideoThumb?.fields?.file?.url}
                                />
                              ) : null}

                              {itemContent.fields.headline ? (
                                <CmsHeadline
                                  headline={itemContent.fields.headline}
                                  headlineTag={itemContent.fields.headline_type}
                                />
                              ) : null}
                              {itemContent.fields.text ? (
                                <CmsRichtext
                                  text={itemContent.fields.text}
                                  smallPrint={itemContent.fields.small_print}
                                  textFormat={itemContent.fields.text_align}
                                />
                              ) : null}
                            </div>
                            {itemContent.fields.cta ? (
                              <CmsButton
                                types={
                                  !itemContent.fields.headline &&
                                  !itemContent.fields.text &&
                                  !itemContent.fields.small_print &&
                                  itemContent.fields.image
                                    ? 'invisible'
                                    : null
                                }
                                id={itemContent.fields.image ? itemContent.fields.image[0].sys.id : null}
                                relativeLink={
                                  itemContent.fields.cta_link_reference ? itemContent.fields.cta_link_reference : null
                                }
                                absoluteLink={itemContent.fields.cta_link ? itemContent.fields.cta_link : null}
                                productLink={
                                  itemContent.fields.related_product ? itemContent.fields.related_product.fields : null
                                }
                                buttonText={itemContent.fields.cta}
                                tracking={itemContent.fields.tracking}
                                t={t}
                              />
                            ) : null}
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Container>
          </section>
        )}
      </I18n>
    );
  }
  return null;
};

export default HomeListColumn;
