import React from 'react';
import { Value } from 'react-powerplug';
import Container from '../../../layout/Container/Container';
import styles from './CmsModules.module.scss';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsHeadline } from './Components/CmsHeadline';
import { CmsRichtext } from './Components/CmsRichText';

const ElementAccordion = ({ item }) => {
  const fontClasses = item.font_color === false ? [styles.FontColorSecondary] : [];
  const optionClasses = item.type_area === true ? [styles.TypeArea] : [];
  const bgColorFallback = item.background_color ? item.background_color : '#ffffff'; // Fallback so there will be no undefined on inline styles

  return (
    <section style={{ backgroundColor: `${bgColorFallback}` }} data-cms-block="element_accordion">
      <Container className={fontClasses.join(' ')}>
        <CmsRowHeadline item={item} bgColor={bgColorFallback} />
        <div className={styles.oneColumnRow}>
          <div
            className={optionClasses.join(' ')}
            itemScope={item.seo_faq}
            itemType={item.seo_faq && 'https://schema.org/FAQPage'}
          >
            <Value>
              {({ value, set }) =>
                item.accordion_content.map((accordionItem, index) => {
                  const key = `accordion${index}`;
                  const activeClass = value === key ? styles.Active : null;

                  if (accordionItem.fields.item_head && accordionItem.fields.item_content) {
                    return (
                      <React.Fragment>
                        <div
                          key={key}
                          itemScope={item.seo_faq}
                          itemProp={item.seo_faq && 'mainEntity'}
                          itemType={item.seo_faq && 'https://schema.org/Question'}
                          className={[styles.AccordionItem, activeClass].join(' ')}
                        >
                          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                          <div
                            className={[styles.AccordionHead, 'after-icon-chevron-down'].join(' ')}
                            onClick={() => (value === key ? set(null) : set(key))}
                          >
                            <CmsHeadline
                              headline={accordionItem.fields.item_head}
                              headlineTag="h3"
                              itemProp={item.seo_faq && 'name'}
                            />
                          </div>
                          <div
                            className={styles.AccordionContent}
                            itemProp={item.seo_faq && 'acceptedAnswer'}
                            itemScope={item.seo_faq}
                            itemType={item.seo_faq && 'https://schema.org/Answer'}
                          >
                            <CmsRichtext
                              smallPrint={accordionItem.fields.small_print}
                              text={accordionItem.fields.item_content}
                              itemProp={item.seo_faq && 'text'}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }
                  return null;
                })
              }
            </Value>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ElementAccordion;
