import React from 'react';
import Container from '../../../layout/Container/Container';
import styles from './CmsModules.module.scss';
import { CmsImage } from './Components/CmsImages';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsHeadline } from './Components/CmsHeadline';
import { CmsRichtext } from './Components/CmsRichText';
import { CmsButton } from './Components/CmsButton';
import { CmsVideo } from './Components/CmsVideo';

const ElementInfoboxTwoColumn = ({ item, row }) => {
  const wrapperBgColor = item.background_color ? item.background_color : '#ffffff';
  const fontClasses = item.font_color === false ? [styles.FontColorSecondary] : [];
  const optionClasses = item.text_align === false ? [styles.TextAlign, styles.Column] : [styles.Column];
  const spaceClasses = item.bottom_space === true ? [styles.SpaceRemoval] : [];
  const twoColumnClasses = [styles.twoColumnRow];
  const count = 2;
  const columnContent = [];

  if (item.align_button === false) {
    optionClasses.push(styles.ColumnFlexOff);
  }

  if (item.center_content === true) {
    twoColumnClasses.push(styles.HorizontalAlign);
  }

  for (let i = 1; i <= count; i++) {
    const columnVideo = item[`video_${i}`] ? item[`video_${i}`] : null;
    const columnImage = item[`image_${i}`] && item[`image_${i}`].length ? item[`image_${i}`][0] : null;
    const columnTarget = columnImage ? item[`image_${i}`][0].sys.id : null;
    const columnImageLarge = columnImage ? item[`image_${i}`][0].fields.file.url : null;
    const columnImageSmall = columnImage && item[`image_${i}`][1] ? item[`image_${i}`][1].fields.file.url : null;
    const columnImageAlt = columnImage ? item[`image_${i}`][0].fields : null;
    const columnImageCopyright = item[`image_copyright_${i}`];
    const columnIcon = item[`icon_${i}`] ? item[`icon_${i}`].fields.file.url : null;
    const columnHeadlineType = item[`headline_type_${i}`];
    const columnHeadline = item[`headline_${i}`];
    const columnText = item[`text_${i}`];
    const columnSmallPrint = item[`small_print_${i}`];
    const columnTextFormat = item[`text_align_${i}`];
    const columnCta = item[`cta_${i}`];
    const columnCtaLinkAbsolute = item[`cta_link_${i}`] ? item[`cta_link_${i}`] : null;
    const columnTrackID = item[`tracking_${i}`];
    const columnBgColor = item[`bgColorBox_${i}`] ? item[`bgColorBox_${i}`] : '#ffffff';

    columnContent.push(
      <React.Fragment key={`column-${i}`}>
        <div className={styles.infoBoxStyle} style={{ backgroundColor: `${columnBgColor}` }}>
          {columnImage ? (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              className={columnCtaLinkAbsolute ? 'Interactive' : null}
              onClick={columnCtaLinkAbsolute ? () => document.getElementById(`${columnTarget}`).click() : null}
            >
              <CmsImage
                largeImage={columnImageLarge}
                smallImage={columnImageSmall}
                imageAlt={columnImageAlt}
                imgCopyright={columnImageCopyright}
                source="ElementInfoboxTwoColumn.js"
                useHeight={false}
                row={row}
                fetchpriority={row === 0}
              />
            </div>
          ) : null}
          {!columnImage && columnVideo ? (
            <CmsVideo videoId={columnVideo} videoCopyright={columnImageCopyright} />
          ) : null}
          {columnHeadline ? <CmsHeadline headline={columnHeadline} headlineTag={columnHeadlineType} /> : null}
          {columnText ? (
            <div className="">
              {columnIcon ? (
                <div className={styles.IconAboveText}>
                  <CmsImage largeImage={columnIcon} smallImage={columnIcon} source="ElementTwoColumns.js - 2" />{' '}
                </div>
              ) : null}
              {columnText ? (
                <CmsRichtext text={columnText} smallPrint={columnSmallPrint} textFormat={columnTextFormat} />
              ) : null}
            </div>
          ) : null}
          {columnCta ? (
            <CmsButton
              types={!columnHeadline && !columnText && !columnSmallPrint && columnImage ? 'invisible' : null}
              id={columnTarget}
              absoluteLink={columnCtaLinkAbsolute}
              buttonText={columnCta}
              tracking={columnTrackID}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }

  return (
    <section
      style={{ backgroundColor: `${wrapperBgColor}` }}
      className={spaceClasses.join(' ')}
      data-cms-block="infobox_2_column"
    >
      <Container className={fontClasses.join(' ')}>
        <CmsRowHeadline item={item} bgColor={wrapperBgColor} />
        <div className={twoColumnClasses.join(' ')}>{columnContent}</div>
      </Container>
    </section>
  );
};

export default ElementInfoboxTwoColumn;
