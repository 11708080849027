import React, { useState } from 'react';
import { I18n } from '@deity/falcon-i18n';
import { useKeenSlider } from 'keen-slider/react';
import Container from '../../../layout/Container/Container';
import styles from './CmsModules.module.scss';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsImage } from './Components/CmsImages';
import { CmsVideo } from './Components/CmsVideo';
import { CmsHeadline } from './Components/CmsHeadline';
import { CmsRichtext } from './Components/CmsRichText';
import { CmsButton } from './Components/CmsButton';
import { CmsTeaserBox } from './Components/CmsTeaserBox';
import { CmsWidget } from './Components/CmsWidget';
import { CmsCfVideo } from './Components/CmsCfVideo';

const HomeSlideColumn = ({ item, row }) => {
  const fontClasses = item.font_color === false ? [styles.FontColorSecondary] : [];
  const optionClasses =
    item.text_align === false ? [styles.TextAlign, styles.RowColumn, styles.Column] : [styles.RowColumn, styles.Column];
  const spaceClasses = item.bottom_space === true ? [styles.SpaceRemoval] : [];
  const wrapperBgColor = item.background_color ? item.background_color : '#ffffff';

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    drag: true,
    breakpoints: {
      '(min-width: 460px)': {
        slides: { perView: 2, spacing: 20 },
        drag: true
      },
      '(min-width: 768px)': {
        slides: { perView: 3, spacing: 20 },
        drag: true
      },
      '(min-width: 992px)': {
        slides: { perView: 4, spacing: 20 },
        drag: false
      }
    },
    slides: {
      perView: 1,
      spacing: 20
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    }
  });

  const Arrow = props => {
    const disabled = props.disabled ? styles.ArrowDisabled : '';
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        onClick={props.onClick}
        className={[styles.Arrow, props.left ? styles.ArrowPrev : styles.ArrowNext, disabled].join(' ')}
        aria-label={props.left ? 'prev' : 'next'}
      />
    );
  };

  if (item.column_content_row && item.column_content_row.length >= 3) {
    const sliderColumns = item.column_content_row.map((itemContent, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="keen-slider__slide" key={`item-${index}`}>
        <I18n>
          {t => (
            <>
              {itemContent.sys.contentType.sys.id === 'home_teaser_box' ? (
                <CmsTeaserBox
                  title={itemContent.fields.title}
                  subline={itemContent.fields.subline}
                  image={itemContent.fields.image ? itemContent.fields.image : null}
                  relativeLink={itemContent.fields.cta_link_reference ? itemContent.fields.cta_link_reference : null}
                  absoluteLink={itemContent.fields.cta_link ? itemContent.fields.cta_link : null}
                  productLink={itemContent.fields.related_product ? itemContent.fields.related_product.fields : null}
                  labelType={itemContent.fields.label_type}
                  tracking={itemContent.fields.tracking}
                  creativeSlot={index}
                  product={
                    itemContent.fields.related_product ? itemContent.fields.related_product.fields.products[0] : null
                  }
                  t={t}
                  row={row}
                />
              ) : (
                <div className={optionClasses.join(' ')}>
                  {itemContent.fields.widget ? (
                    <CmsWidget
                      itemContent={itemContent}
                      widget={itemContent.fields.widget}
                      widgetSource={itemContent.fields.widget_source}
                    />
                  ) : (
                    <React.Fragment>
                      <div>
                        {itemContent.fields.image ? (
                          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                          <div
                            className={
                              itemContent.fields.cta_link_reference ||
                              itemContent.fields.related_product ||
                              itemContent.fields.cta_link
                                ? 'Interactive'
                                : null
                            }
                            onClick={
                              itemContent.fields.cta_link_reference ||
                              itemContent.fields.related_product ||
                              itemContent.fields.cta_link
                                ? () => document.getElementById(`${itemContent.fields.image[0].sys.id}`).click()
                                : null
                            }
                          >
                            <CmsImage
                              largeImage={itemContent.fields.image[0].fields.file.url}
                              smallImage={
                                itemContent.fields.image[1] ? itemContent.fields.image[1].fields.file.url : null
                              }
                              imageAlt={itemContent.fields.image[0].fields}
                              imgCopyright={itemContent.fields.image_copyright}
                              source="HomeSlideColumn.js"
                              row={row}
                            />
                          </div>
                        ) : null}

                        {!itemContent.fields.image && itemContent.fields.video ? (
                          <CmsVideo
                            videoId={itemContent.fields.video}
                            videoCopyright={itemContent.fields.image_copyright}
                          />
                        ) : null}

                        {itemContent?.fields?.cfVideo?.fields?.file?.url ? (
                          <CmsCfVideo
                            videoUrl={itemContent?.fields?.cfVideo?.fields?.file?.url}
                            videoTitle={itemContent?.fields?.cfVideo?.fields?.title}
                            videoThumb={itemContent?.fields?.cfVideoThumb?.fields?.file?.url}
                          />
                        ) : null}

                        {itemContent.fields.headline ? (
                          <CmsHeadline
                            headline={itemContent.fields.headline}
                            headlineTag={itemContent.fields.headline_type}
                          />
                        ) : null}
                        {itemContent.fields.text ? (
                          <CmsRichtext
                            text={itemContent.fields.text}
                            smallPrint={itemContent.fields.small_print}
                            textFormat={itemContent.fields.text_align}
                          />
                        ) : null}
                      </div>
                      {itemContent.fields.cta ? (
                        <CmsButton
                          types={
                            !itemContent.fields.headline &&
                            !itemContent.fields.text &&
                            !itemContent.fields.small_print &&
                            itemContent.fields.image
                              ? 'invisible'
                              : null
                          }
                          id={itemContent.fields.image ? itemContent.fields.image[0].sys.id : null}
                          relativeLink={
                            itemContent.fields.cta_link_reference ? itemContent.fields.cta_link_reference : null
                          }
                          absoluteLink={itemContent.fields.cta_link ? itemContent.fields.cta_link : null}
                          productLink={
                            itemContent.fields.related_product ? itemContent.fields.related_product.fields : null
                          }
                          buttonText={itemContent.fields.cta}
                          tracking={itemContent.fields.tracking}
                          t={t}
                        />
                      ) : null}
                    </React.Fragment>
                  )}
                </div>
              )}
            </>
          )}
        </I18n>
      </div>
    ));

    return (
      <section
        className={spaceClasses.join(' ')}
        style={{ backgroundColor: `${wrapperBgColor}` }}
        data-cms-block="home_slide_column"
      >
        <Container className={fontClasses.join(' ')}>
          <CmsRowHeadline item={item} bgColor={wrapperBgColor} />
          <div className={styles.SliderWrapper}>
            <div className="navigation-wrapper">
              <div ref={sliderRef} className="keen-slider">
                {sliderColumns}
              </div>
              {loaded && instanceRef.current && (
                <>
                  <Arrow
                    left
                    onClick={e =>
                      e.stopPropagation() ||
                      instanceRef.current?.moveToIdx(
                        instanceRef.current.track.details.abs - instanceRef.current.options.slides.perView < 0
                          ? 0
                          : instanceRef.current.track.details.abs - instanceRef.current.options.slides.perView
                      )
                    }
                    disabled={currentSlide === 0}
                  />

                  <Arrow
                    onClick={e =>
                      e.stopPropagation() ||
                      instanceRef.current?.moveToIdx(
                        instanceRef.current.track.details.abs + instanceRef.current.options.slides.perView >
                        instanceRef.current.track.details.slides.length
                          ? instanceRef.current.track.details.slides.length - 1
                          : instanceRef.current.track.details.abs + instanceRef.current.options.slides.perView
                      )
                    }
                    disabled={
                      currentSlide ===
                      instanceRef?.current?.track?.details?.slides?.length - instanceRef.current.options.slides.perView
                    }
                  />
                </>
              )}
              {loaded && instanceRef.current && (
                <div className="dots ShowTablet ShowMobile elements">
                  {[
                    ...Array(
                      instanceRef.current.track.details.slides.length - instanceRef.current.options.slides.perView + 1 >
                        1
                        ? instanceRef.current.track.details.slides.length -
                            instanceRef.current.options.slides.perView +
                            1
                        : 0
                    ).keys()
                  ].map(idx => {
                    return (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      <span
                        key={idx}
                        onClick={() => {
                          instanceRef.current?.moveToIdx(idx);
                        }}
                        className={`dot${currentSlide === idx ? ' active' : ''}`}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
    );
  }
  return null;
};

export default HomeSlideColumn;
