import React from 'react';
import { T } from '@deity/falcon-i18n';
import Container from '../../../layout/Container/Container';
import styles from './CmsModules.module.scss';

const AuthorBox = ({ item }) => {
  const authorName = item?.author_name;
  const authorBio = item?.author_bio;
  const authorImage = item?.author_image?.fields?.file?.url || null;

  return (
    <section className={styles.AuthorBox} data-cms-block="author_box">
      <Container>
        <div className={styles.AuthorBoxWrap}>
          {authorImage ? (
            <div className={styles.AuthorBoxImage}>
              <img src={authorImage} alt={authorName} />
            </div>
          ) : null}
          <div className={styles.AuthorBio}>
            <p>
              <T id="gallery.from" />
              <strong> {authorName}</strong>
            </p>
            {authorBio ? (
              <p>
                <small>{authorBio}</small>
              </p>
            ) : null}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AuthorBox;
