import React from 'react';
import { LazyLoad } from '../../../../helpers/LazyLoad/LazyLoad';
import useCheckBreakpoints from '../../../../hooks/CheckBreakpoint';

export const CmsImageBg = ({ largeImage, smallImage }) => {
  const { isDesktop, isMobile, isTablet } = useCheckBreakpoints();
  // For debug, add 'source' to parameters and console.log it in order to see where the image comes from.
  let image = null;

  if (isDesktop) {
    image = largeImage;
  }

  if (isTablet) {
    image = smallImage || largeImage;
  }

  if (isMobile) {
    image = smallImage || largeImage;
  }

  return (
    <LazyLoad>
      <div className="bgImg-holder" style={{ backgroundImage: `url(${image})` }} />
    </LazyLoad>
  );
};
